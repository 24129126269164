const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  FORGOT_ERROR: 'FORGOT_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  SUBMODULES: 'SUBMODULES',
  SET_LOADING: 'SET_LOADING',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_DONE: 'FORGOT_PASSWORD_DONE',
  SIGNUP: 'SIGNUP',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  RESET_SECOND_TIMER: 'RESET_SECOND_TIMER',
  ON_RESET_TIMER: 'ON_RESET_TIMER',
  LOGIN_VALIDATOR: 'LOGIN_VALIDATOR',
  FORBIDDEN_REQUEST: 'FORBIDDEN_REQUEST',
  KICKED_OUT: 'KICKED_OUT',
  KICKED_OUT_CLEAR: 'KICKED_OUT_CLEAR',
  STORE_UPDATE: 'STORE_UPDATE',
  TOGGLE_KYC: 'TOGGLE_KYC',
  SHOW_KYC: 'SHOW_KYC',
  FULLY_REGISTERED: 'FULLY_REGISTERED',
  CHANGE_MERCHANT: 'CHANGE_MERCHANT',
  CHANGE_MERCHANT_SUCCESS: 'CHANGE_MERCHANT_SUCCESS',
  FETCH_STORES: 'FETCH_STORES',
  FETCH_STORES_SUCCESS: 'FETCH_STORES_SUCCESS',
  // GET REDIRECT URL
  FETCH_REDIRECT_URL: 'FETCH_REDIRECT_URL',
  FETCHING_REDIRECT_URL: 'FETCHING_REDIRECT_URL',
  FETCHED_REDIRECT_URL: 'FETCHED_REDIRECT_URL',
  FETCH_REDIRECT_URL_FAILED: 'FETCH_REDIRECT_URL_FAILED',
  // REGISTRATION SUCCESS MQTT DATA
  REDIRECT_REGISTRATION_SUCCESS: 'REDIRECT_REGISTRATION_SUCCESS',
  // RESEND REGISTRATION EMAIL
  RESEND_REGISTRATION_EMAIL: 'RESEND_REGISTRATION_EMAIL',
  SENDING_REGISTRATION_EMAIL: 'SENDING_REGISTRATION_EMAIL',
  SENT_REGISTRATION_EMAIL: 'SENT_REGISTRATION_EMAIL',
  RESEND_REGISTRATION_EMAIL_FAILED: 'RESEND_REGISTRATION_EMAIL_FAILED',
  RESET_RESEND_REGISTRATION_EMAIL: 'RESET_RESEND_REGISTRATION_EMAIL',
  // RESEND REGISTRATION BATCH UPLOAD EMAIL
  RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL: 'RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL',
  SENDING_REGISTRATION_BATCH_UPLOAD_EMAIL: 'SENDING_REGISTRATION_BATCH_UPLOAD_EMAIL',
  SENT_REGISTRATION_BATCH_UPLOAD_EMAIL: 'SENT_REGISTRATION_BATCH_UPLOAD_EMAIL',
  RESEND_REGISTRATION_EMAIL_BATCH_UPLOAD_FAILED: 'RESEND_REGISTRATION_EMAIL_BATCH_UPLOAD_FAILED',
  RESET_RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL: 'RESET_RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL',
  // GET RESET PASSWORD URL
  FETCH_RESET_PASSWORD_URL: 'FETCH_RESET_PASSWORD_URL',
  FETCHING_RESET_PASSWORD_URL: 'FETCHING_RESET_PASSWORD_URL',
  FETCHED_RESET_PASSWORD_URL: 'FETCHED_RESET_PASSWORD_URL',
  FETCH_RESET_PASSWORD_URL_FAILED: 'FETCH_RESET_PASSWORD_URL_FAILED',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload: payload,
  }),
  signup: (payload) => ({
    type: actions.SIGNUP,
    loading: true,
    payload: payload,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
  setSubmodules: (payload) => ({
    type: actions.SUBMODULES,
    modules: payload,
  }),
  forgotPassword: (email) => ({
    type: actions.FORGOT_PASSWORD,
    email,
  }),
  resetSecondTimer: (payload) => ({
    type: actions.RESET_SECOND_TIMER,
    secondTimer: payload,
  }),
  unKick: () => ({
    type: actions.KICKED_OUT_CLEAR,
  }),
  toggleKyc: () => ({
    type: actions.TOGGLE_KYC,
  }),
  showKyc: () => ({
    type: actions.SHOW_KYC,
  }),
  changeMerchant: (payload) => ({
    type: actions.CHANGE_MERCHANT,
    payload,
  }),
  fetchStore: () => ({
    type: actions.FETCH_STORES
  }),
  fetchRedirectUrl: () => ({
    type: actions.FETCH_REDIRECT_URL,
  }),
  resendRegistrationEmail: (payload) => ({
    type: actions.RESEND_REGISTRATION_EMAIL,
    payload,
  }),
  resetResendRegistrationEmail: () => ({
    type: actions.RESET_RESEND_REGISTRATION_EMAIL,
  }),
  resendRegistrationBatchUploadEmail: (payload) => ({
    type: actions.RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL,
    payload,
  }),
  resetResendRegistrationBatchUploadEmail: () => ({
    type: actions.RESET_RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL,
  }),
  fetchResetPassUrl: () => ({
    type: actions.FETCH_RESET_PASSWORD_URL,
  }),
};
export default actions;
