import UAParser from 'ua-parser-js';
import { pushToIos } from './iOSCommKit'
import { pushToAndroid } from './androidCommKit'

const parser = new UAParser();

// console.log('PARSER', JSON.stringify(parser.getResult()))

export const getAgent = () => {

    // PARAMS FROM AGENT
    // browser.name - name of browser where the system is being viewed
    // os.name - name of device OS, i.e. Android, BlackBerry, iOS, Windows [Phone/Mobile]
    // os.version - version of device OS
    // device.vendor - name of device manufacturer, i.e. Samsung, Apple, Google, LG, etc.
    // device.model - name of device model
    // device.type - type of device model, i.e. console, mobile, tablet, smarttv, wearable, embeddedv

    return parser.getResult()
}

export const pushDataToApp = (key, payload) => {
    const agent = getAgent();
    // console.log('CHECK DEVICE AGENT', agent);
    // console.log('CHECK DATA IN PUSH TO APP', [key, payload]);
    if (agent.os.name === 'iOS' || agent.os.name === 'Mac OS') {
        // iOS for iPhone, Mac OS for iPad desktop-class browsing
        pushToIos({
            key,
            payload,
        });
    } else if (agent.os.name === 'Android') {
        pushToAndroid({
            key,
            payload,
        });
    }
};

export const getPlatform = () => {
    const agent = getAgent();
    return {
        platform_os: agent.os.name,
        platform_vendor: agent.device.vendor,
        platform_engine: agent.engine.name,
        platform_model: agent.device.model,
        platform_type: agent.device.type,
    }
};

