export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  DOWNLOAD_APP: '/download_app',
  REDIRECT_CUSTOMER_INFO: '/redirect_customer_info/:id',
  REDIRECT: '/redirect/:id/:customer?',
  CUSTOMER_PAYMENT: '/customer_payment/:id',
  REDIRECT_SUCCESS: '/redirect_success',
  REDIRECT_SUCCESS_BOC: '/redirect_success_boc/:id',
  REDIRECT_SUCCESS_DETAILED: '/redirect_success_detailed/:id',
  REDIRECT_FAILED: '/redirect_failed',
  REDIRECT_EXPIRED: '/redirect_expired',
  REDIRECT_PAYMENT_CHANNEL: '/redirect_payment_channel/:id',
  REDIRECT_ERROR: '/redirect_error',
  REDIRECT_REFUND: '/redirect_refund',
  REDIRECT_PENDING: '/redirect_pending',
  REDIRECT_STORE_NOT_ALLOWED: '/redirect_store_not_found',
  REDIRECT_VERIFIED_EMAIL: '/redirect_verified_email/:id',
  MERCHANT_QR: '/merchant_qr/:id',
  POS_MERCHANT: '/pos_merchant/:id',
  MERCHANT_KYC_DISPLAY: '/merchant_kyc_display/:id',
  REGISTRATION_SUCCESS: '/registration_success/:id',
  REGISTRATION_VERIFIED_EMAIL: '/registration_verified_email/:id',
  RESET_PASSWORD: '/reset_password/:id',
  REDIRECT_RESET_SUCCESS: '/redirect_reset_success',
  REDIRECT_RESET_EXPIRED: '/redirect_reset_expired',
  REDIRECT_RESET_USED: '/redirect_reset_used',
  REDIRECT_INVENTORY: '/redirect_inventory/:id',
  REDIRECT_VERIFY: '/redirect_verify/:id',
  REDIRECT_TOPUP_SUCCESS: '/redirect_topup_success',
  REDIRECT_TOPUP_FAILED: '/redirect_topup_failed/',
  TERMS_AND_CONDITIONS: '/terms_and_conditions',
  MOBILE_OPTION: '/mobile_option',
  SIGN_UP_REFER: '/signup/:referralCode',
  FORGET_PASSWORD: '/forgotpassword',
  RESETPASSWORD: '/resetpassword',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
};



// export const PRIVATE_ROUTE = {
//   DASHBOARD: '/',
//   MAIL: '/inbox',
//   SCRUM_BOARD: '/scrum-board',
//   CALENDAR: '/calendar',
//   GOOGLE_MAP: '/googlemap',
//   LEAFLET_MAP: '/leafletmap',
//   TABLE: '/table_ant',
//   FORM: '/allFormComponent',
//   INPUT: '/InputField',
//   EDITOR: '/editor',
//   FORM_WITH_STEPPERS: '/stepperForms',
//   FORM_WITH_VALIDATION: '/FormsWithValidation',
//   PROGRESS: '/progress',
//   BUTTON: '/button',
//   TAB: '/tab',
//   AUTOCOMPLETE: '/autocomplete',
//   CHECKBOX: '/checkbox',
//   RADIOBOX: '/radiobox',
//   SELECT: '/selectbox',
//   TRANSFER: '/transfer',
//   GRID_LAYOUT: '/gridLayout',
//   NOTES: '/notes',
//   TODO: '/todo',
//   ARTICLES: '/articles',
//   INVESTORS: '/investors',
//   CONTACTS: '/contacts',
//   ALERT: '/alert',
//   MODAL: '/modal',
//   MESSAGE: '/message',
//   NOTIFICATION: '/notification',
//   POPCONFIRM: '/Popconfirm',
//   SPIN: '/spin',
//   SHUFFLE: '/shuffle',
//   AFFIX: '/affix',
//   BREADCRUMB: '/breadcrumb',
//   BACK_TO_TOP: '/backToTop',
//   DROPDOWN: '/dropdown',
//   OP_BADGE: '/op_badge',
//   OP_CARD: '/op_card',
//   OP_CAROUSEL: '/op_carousel',
//   OP_COLLAPSE: '/op_collapse',
//   OP_TOOLTIP: '/op_tooltip',
//   RATING: '/rating',
//   TREE: '/tree',
//   OP_TAG: '/op_tag',
//   OP_TIMELINE: '/op_timeline',
//   OP_POPOVER: '/op_popover',
//   GOOGLE_CHART: '/googleChart',
//   RECHARTS: '/recharts',
//   MENU: '/menu',
//   REACT_CHART_2: '/ReactChart2',
//   PAGINATION: '/pagination',
//   CARD: '/card',
//   CART: '/cart',
//   CHECKOUT: '/checkout',
//   SHOP: '/shop',
//   REACT_DATES: '/reactDates',
//   CODE_MIRROR: '/codeMirror',
//   UPPY: '/uppy',
//   DROPZONE: '/dropzone',
//   YOUTUBE_SEARCH: '/youtubeSearch',
//   FRAPPE_CHART: '/frappeChart',
//   INVOICE: '/invoice',
//   CHAT: '/chat',
//   PROFILE: '/my-profile',
//   SWIPER_SLIDER: '/swiperslider',
//   CORPORATE_MERCHANT: '/CorporateMerchant',
// };
