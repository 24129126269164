import { put } from 'redux-saga/effects';
import actions from '@iso/redux/auth/actions';


export const pushToIos = data => {
    console.log('PUSH TO APP A', data);
    if (window.webkit) {
        console.log('PUSH TO APP B', window.webkit);
        window.webkit.messageHandlers.eventHandler.postMessage(data);
    }
};

export const pushIosToWeb = data => {
    if (window.webkit) {
        // window.webkit.messageHandlers.eventHandler.postMessage(data);
        // console.log('FROM_MOBILE_APP', data)
        if (data.topic === 'VERIFY_IDENTITY_CLICKED') {
            hideKYCDialog();
        }
    }
};

function hideKYCDialog() {
    // hide KYC dialog
    // console.log('FROM_MOBILE_APP', 'TOGGLE_KYC')
    put({
        type: actions.TOGGLE_KYC,
    });
}

window.pushIosToWeb = pushIosToWeb;
window.hideKYCDialog = hideKYCDialog;




// if (window.webkit) {

// }