import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';
// import VerifyRequest from '@iso/containers/Pages/VerifyRequest/VerifyRequest';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
// import TimoutProvider from '@iso/lib/helpers/timeoutManager';

// Added network notification - this includes all the screen inside the app
const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    // Added network notification
    path: PUBLIC_ROUTE.DOWNLOAD_APP,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/LandingDownload/LandingDownload')),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.TERMS_AND_CONDITIONS,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/Terms/TermsCondtions')),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.MOBILE_OPTION,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/Mobileoption/Mobileoption')),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.SIGN_UP_REFER,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ForgotPassword/ForgotPassword')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.RESETPASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ResetPassword/ResetPassword')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_CUSTOMER_INFO,
    component: lazy(() =>
      import('@iso/containers/Redirects/RedirectCustomerInfo/RedirectCustomerInfo')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT,
    component: lazy(() => import('@iso/containers/Redirects/Store/Store'))
    // component: lazy(() =>
    //   import('@iso/containers/RedirectHandler/RedirectHandler')
    // ),
  },
  {
    path: PUBLIC_ROUTE.CUSTOMER_PAYMENT,
    component: lazy(() =>
      import('@iso/containers/RedirectHandler/RedirectHandler')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_SUCCESS,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/Success/RedirectSuccess')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_SUCCESS_BOC,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/Success/RedirectSuccessBoc')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_SUCCESS_DETAILED,
    component: lazy(() =>
      import('@iso/containers/Redirects/Success/RedirectSuccessDetailed')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_FAILED,
    component: lazy(() =>
      import('@iso/containers/Redirects/Failed/RedirectFailed')
    ),
  },
  {
    path: PUBLIC_ROUTE.REDIRECT_EXPIRED,
    component: lazy(() =>
      import('@iso/containers/Redirects/Expired/RedirectExpired')
    ),
  },
  {
    path: PUBLIC_ROUTE.REDIRECT_PAYMENT_CHANNEL,
    component: lazy(() =>
      import('@iso/containers/Redirects/RedirectPaymentChannel/RedirectPayment')
    ),
  },
  {
    path: PUBLIC_ROUTE.REDIRECT_ERROR,
    component: lazy(() =>
      import('@iso/containers/Redirects/Error/RedirectError')
    ),
  },
  {
    path: PUBLIC_ROUTE.REDIRECT_REFUND,
    component: lazy(() =>
      import('@iso/containers/Redirects/Refund/RedirectRefund')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_PENDING,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/Pending/RedirectPending')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_STORE_NOT_ALLOWED,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/StoreNotAvailable/RedirectStoreNotAvailable')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_VERIFIED_EMAIL,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/VerifiedEmail/RedirectVerifiedEmail')
    ),
  },
  {
    path: PUBLIC_ROUTE.MERCHANT_QR,
    component: lazy(() =>
      import('@iso/containers/RedirectHandler/RedirectHandler')
    ),
  },
  {
    path: PUBLIC_ROUTE.POS_MERCHANT,
    component: lazy(() =>
      import('@iso/containers/RedirectHandler/RedirectHandler')
    ),
  },
  {
    path: PUBLIC_ROUTE.MERCHANT_KYC_DISPLAY,
    component: lazy(() =>
      import('@iso/containers/RedirectHandler/RedirectHandler')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REGISTRATION_SUCCESS,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/RegistrationSuccess/RegistrationSuccess')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REGISTRATION_VERIFIED_EMAIL,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/RegistrationVerifiedEmail/RedirectRegistrationVerifiedEmail')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_RESET_SUCCESS,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/ResetSuccess/RedirectResetSuccess')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_RESET_EXPIRED,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/ResetExpired/RedirectResetExpired')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_RESET_USED,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/ResetUsed/RedirectResetUsed')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_INVENTORY,
    component: lazy(() => import('@iso/containers/Redirects/Inventory/Inventory'))
    // component: lazy(() =>
    //   import('@iso/containers/RedirectHandler/RedirectHandler')
    // ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/ResetPassword/RedirectResetPassword')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_VERIFY,
    component: lazy(() => import('@iso/containers/Pages/VerifyRequest/VerifyRequest')),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_TOPUP_SUCCESS,
    component: lazy(() =>
    import('@iso/containers/Redirects/WalletTopUp/TopupSuccess')
    ),
  },
  {
    // Added network notification
    path: PUBLIC_ROUTE.REDIRECT_TOPUP_FAILED,
    component: lazy(() =>
    import('@iso/containers/Redirects/WalletTopUp/TopupFailed')
    ),
  },
];
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: location },
              }}
            />
          )
      }
    />
  );
}

export default function Routes() {
  return (
    // <TimoutProvider>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Router>
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                  <route.component />
                </Route>
              ))}
              <PrivateRoute path="/portal">
                <Dashboard />
              </PrivateRoute>
            </Switch>
          </Router>
        </Suspense>
      </ErrorBoundary>
    // </TimoutProvider>
  );
}
