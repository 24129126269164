import { all, put, fork, takeEvery, select, cancel } from 'redux-saga/effects';
import actions from './actions';
import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';
import * as _ from 'lodash';

export function* withdrawalTransaction() {
  yield takeEvery('GET_WITHDRAWAL_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Sales' && role !== 'Finance' && role !== 'ADMU Cashier' && role !== 'ADMU CAO' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.mid = merchantId;
    const loadmore = payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.LOADMORE_WITHDRAWAL_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.GETTING_WITHDRAWAL_TRANSACTION,
        });
      }
      // const apiResultD = yield withdrawalRequesting();
      // const apiResultD = yield withdrawalRequestingNew(merchantId, role, selectedMerchant);
      const apiResultD = yield withdrawalTransactionRequest(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        const currentList = payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.GET_WITHDRAWAL_TRANSACTION_SUCCESS,
          payload: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.GET_WITHDRAWAL_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_WITHDRAWAL_TRANSACTION_ERROR,
      });
    }
  });
}
export function* splitTransaction() {
  yield takeEvery('GET_SPLIT_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Sales' && role !== 'Finance' && role !== 'ADMU Cashier' && role !== 'ADMU CAO' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.mid = merchantId;
    const loadmore = payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.LOADMORE_SPLIT_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.GETTING_SPLIT_TRANSACTION,
        });
      }
      const apiResultD = yield splitTransactionRequest(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        const currentList = payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.GET_SPLIT_TRANSACTION_SUCCESS,
          payload: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.GET_SPLIT_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_SPLIT_TRANSACTION_ERROR,
      });
    }
  });
}
export function* topupTransaction() {
  yield takeEvery('GET_TOPUP_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const identity = state.Auth.identity;
    const merchantId = role !== 'Administrator' && role !== 'Sales' && role !== 'Finance' && role !== 'ADMU Cashier' && role !== 'ADMU CAO' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.mid = merchantId;
    payload.tenantId = identity.tenantId;
    const loadmore = payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.LOADMORE_TOPUP_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.GETTING_TOPUP_TRANSACTION,
        });
      }
      const apiResultD = yield topupTransactionRequest(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        const currentList = payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.GET_TOPUP_TRANSACTION_SUCCESS,
          payload: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.GET_TOPUP_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_TOPUP_TRANSACTION_ERROR,
      });
    }
  });
}
export function* getOnlinePaymentTransaction() {
  yield takeEvery('GET_ONLINE_PAYMENT_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const identity = state.Auth.identity;
    const merchantId = role !== 'Administrator' && role !== 'Sales' && role !== 'Finance' && role !== 'ADMU Cashier' && role !== 'ADMU CAO' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.mid = merchantId;
    payload.tenantId = identity.tenantId;
    const loadmore = payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.LOADMORE_ONLINE_PAYMENT_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.GETTING_ONLINE_PAYMENT_TRANSACTION,
        });
      }
      const apiResultD = yield onlinePaymentTransactionRequest(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        const currentList = payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.GET_ONLINE_PAYMENT_TRANSACTION_SUCCESS,
          payload: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.GET_ONLINE_PAYMENT_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_ONLINE_PAYMENT_TRANSACTION_ERROR,
      });
    }
  });
}
export function* loadBalance() {
  yield takeEvery('LOAD_WALLET_BALANCE', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Sales' && role !== 'Finance' && role !== 'ADMU Cashier' && role !== 'ADMU CAO' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const loadingBalances = state.Financials.loadingBalances;
    if (!loadingBalances) {
      try {
        yield put({
          type: actions.LOADING_BALANCES,
        });
        yield put({
          type: actions.LOADING_LIMIT,
        });
        yield put({
          type: actions.LOADING_BANK_ACCOUNTS,
        });
        const apiResult = yield fetchBalance(merchantId);
        const result = apiResult.data;
        if (result.success) {
          const apiResultB = yield fetchWithdrawableBalance(merchantId);
          const resultB = apiResultB.data.withdrawalBalance || '0.00';
          if (apiResultB.data.success) {
            const walletDetails = {
              ...result.data,
              withdrawable: resultB,
            }
            yield put({
              type: actions.BALANCES_LOADED,
              walletDetails: walletDetails,
            });

            const apiResultC = yield getLimit(merchantId);
            const resultc = apiResultC.data || null;
            if (apiResultC.status === 200) {
              yield put({
                type: actions.LIMIT_LOADED,
                limitDetails: resultc,
              });
              const apiResultD = yield fetchBankAccount(merchantId);
              const resultd = apiResultD.data || null;
              if (resultd.success) {
                yield put({
                  type: actions.BANK_ACCOUNTS_LOADED,
                  bankAccounts: resultd.data,
                });
              } else {
                yield put({
                  type: actions.LOADING_BANK_ACCOUNTS_FAILED,
                });
              }

            } else {
              yield put({
                type: actions.LOADING_LIMIT_FAILED,
              });
            }
          } else {
            yield put({
              type: actions.LOADING_BALANCES_FAILED,
            });
          }

        }
      } catch (error) {
        yield put({
          type: actions.LOADING_BALANCES_FAILED,
        });
      }
    }
  });
}
export function* loadBankAccounts() {
  yield takeEvery('LOAD_BANK_ACCOUNTS', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Sales' && role !== 'Finance' && role !== 'ADMU Cashier' && role !== 'ADMU CAO' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const loadingBalances = state.Financials.loadingBalances;
    if (!loadingBalances) {
      try {
        yield put({
          type: actions.LOADING_BANK_ACCOUNTS,
        });
        const apiResultD = yield fetchBankAccount(merchantId);
        const resultd = apiResultD.data || null;
        if (resultd.success) {
          yield put({
            type: actions.BANK_ACCOUNTS_LOADED,
            bankAccounts: resultd.data,
          });
        } else {
          yield put({
            type: actions.LOADING_BANK_ACCOUNTS_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.LOADING_BANK_ACCOUNTS_FAILED,
        });
      }
    }
  });
}
export function* requestOtp() {
  yield takeEvery('REQUEST_OTP', function* ({ payload }) {
    try {
      yield put({
        type: actions.REQUESTING_OTP,
        payload: true,
      });
      const apiResultD = yield requestingOtp(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        yield put({
          type: actions.REQUESTING_OTP,
          payload: false,
        });
      } else {
        yield put({
          type: actions.REQUEST_OTP_FAILED,
        });
      }
      yield put({
        type: actions.REQUESTED_OTP,
        payload: result.success ? result.data : result.message ? result.message : 'Error in sending otp!',
        isErrorOtp: result.success ? false : true,
      });
    } catch (error) {
      yield put({
        type: actions.REQUEST_OTP_FAILED,
      });
    }
  });
}
export function* verifyOtp() {
  yield takeEvery('VERIFY_OTP', function* ({ signature, payload, accountType }) {
    try {
      yield put({
        type: actions.VERIFYING_OTP,
        payload: true,
      });
      let apiResult;
      if (accountType === 'Corporate') {
        apiResult = yield verifyingOtpCorporate(payload);
      } else {
        apiResult = yield verifyingOtp(signature, payload);
      }
      const result = apiResult.data || null;
      if (result.status_text) {
        yield put({
          type: actions.VERIFIED_OTP,
          payload: 'Your withdraw is currently processing!',
          isErrorVerifyOtp: false,
        });
      } else {
        yield put({
          type: actions.VERIFY_OTP_FAILED,
        });
        if (result.code) {
          yield put({
            type: actions.VERIFIED_OTP,
            payload: result.advise,
            isErrorVerifyOtp: true,
          });
        } else if (result.response_code) {
          yield put({
            type: actions.VERIFIED_OTP,
            payload: result.response_message,
            isErrorVerifyOtp: true,
          });
        }
      }
      yield put({
        type: actions.VERIFYING_OTP,
        payload: false,
      });
    } catch (error) {
      yield put({
        type: actions.VERIFY_OTP_FAILED,
      });
    }
  });
}
export function* loadSupportedBanks() {
  yield takeEvery('LOAD_SUPPORTED_BANKS', function* ({ payload }) {
    const state = yield select();
    const loadBankAccounts = state.Financials.loadBankAccounts;
    if (!loadBankAccounts) {
      try {
        yield put({
          type: actions.LOADING_SUPPORTED_BANKS,
        });
        const apiResult = yield getSupportedBanks();
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.SUPPORTED_BANK_LOADED,
            supportedBanks: result.data,
          });
        } else {
          yield put({
            type: actions.LOADING_SUPPORTED_BANKS_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.LOADING_SUPPORTED_BANKS_FAILED,
        });
      }
    }
  });
}
export function* sendSmsOtp() {
  yield takeEvery('SEND_SMS_OTP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.SENDING_SMS_OTP,
        });
        const apiResult = yield sendOtpSms(payload);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.SMS_OTP_SENT,
            otpMessage: result.message,
            otpSent: true,
          });
        } else {
          yield put({
            type: actions.SENDING_SMS_OTP_FAILED,
            otpMessage: result.message,
            otpSent: false,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SENDING_SMS_OTP_FAILED,
          otpMessage: 'Error on sending sms otp.',
          otpSent: false,
        });
      }
    }
  });
}
export function* saveBankAccount() {
  yield takeEvery('SAVE_BANK_ACCOUNT', function* ({ payload }) {
    // const state = yield select();
    // const merchantId = state.Auth.identity.merchantId;
    //const merchantId = state.Auth.selectedMerchant;
    if (payload) {
      try {
        yield put({
          type: actions.SAVING_BANK_ACCOUNT,
        });
        const apiResult = yield insertBankAccount(payload);
        const result = apiResult.data || null;
        if (result.success) {
          const apiResultB = yield fetchBankAccount(payload.merchantId);
          const bankAccounts = apiResultB.data || null;
          if (bankAccounts.success) {
            yield put({
              type: actions.BANK_ACCOUNT_SAVED,
              result: result.data,
              bankAccounts: bankAccounts.data,
            });
          } else {
            yield put({
              type: actions.BANK_ACCOUNT_SAVED,
              result: result.data,
            });
          }
        } else {
          yield put({
            type: actions.SAVING_BANK_ACCOUNT_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SAVING_BANK_ACCOUNT_FAILED,
        });
      }
    }
  });
}
export function* resendOtp() {
  yield takeEvery('RESEND_OTP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.RESENDING_OTP,
        });
        const apiResult = yield resendOtpSms(payload);
        const result = apiResult.data || null;
        if (result === "Success") {
          yield put({
            type: actions.RESEND_OTP_SENT,
            message: 'OTP successfully resend!',
          });
        } else if (result === 'FAILED NO MOBILE FOUND') {
          yield put({
            type: actions.RESENDING_OTP_FAILED,
            message: 'NO OTP RECIPIENT',
          });
        } else {
          yield put({
            type: actions.RESENDING_OTP_FAILED,
            message: 'OTP failed to resend!',
          });
        }
      } catch (error) {
        yield put({
          type: actions.RESENDING_OTP_FAILED,
          message: 'OTP failed to resend!',
        });
      }
    }
  });
}
export function* wResendOtp() {
  yield takeEvery('W_RESEND_OTP', function* ({ payload }) {
    try {
      yield put({
        type: actions.W_RESENDING_OTP,
        payload: true,
      });
      const apiResultD = yield resendingOtp(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        yield put({
          type: actions.W_RESEND_OTP_SUCCESS,
          payload: result.data,
          isErrorResendOtp: false,
        });
      } else {
        yield put({
          type: actions.W_RESEND_OTP_SUCCESS,
          payload: result.message ? result.message : 'Error in resending otp!',
          isErrorResendOtp: true,
        });
      }
      yield put({
        type: actions.W_RESENDING_OTP,
        payload: false,
      });
    } catch (error) {
      yield put({
        type: actions.W_RESEND_OTP_FAILED,
      });
    }
  });
}
export function* updateBankAccount() {
  yield takeEvery('UPDATE_BANK_ACCOUNT', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.UPDATING_BANK_ACCOUNT,
        });
        const apiResult = yield editBankAccount(payload);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.BANK_ACCOUNT_UPDATED,
            updatedBankAccount: result.data.finalUpdatedData,
            bankAccounts: result.data.bankAccountUpdated,
          });
        } else {
          yield put({
            type: actions.UPDATING_BANK_ACCOUNT_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.UPDATING_BANK_ACCOUNT_FAILED,
        });
      }
    }
  });
}
export function* removeBankAccount() {
  yield takeEvery('REMOVE_BANK_ACCOUNT', function* ({ payload }) {
    const state = yield select();
    const bankAccounts = state.Financials.bankAccounts;
    if (payload) {
      try {
        yield put({
          type: actions.REMOVING_BANK_ACCOUNT,
        });
        const apiResult = yield deleteBankAccount(payload);
        const result = apiResult.data || null;
        if (result.success) {
          const updatedAccounts = _.reject(bankAccounts, { Id: payload });
          yield put({
            type: actions.BANK_ACCOUNT_REMOVED,
            bankAccounts: updatedAccounts,
          });
        } else {
          yield put({
            type: actions.REMOVING_BANK_ACCOUNT_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.REMOVING_BANK_ACCOUNT_FAILED,
        });
      }
    }
  });
}
export function* changeFavorite() {
  yield takeEvery('CHANGE_FAVORITE', function* ({ payload }) {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.CHANGING_FAVORITE,
        });
        const apiResult = yield changeFavoriteValue(payload);
        const result = apiResult.data || null;
        if (result.success) {
          const apiResultB = yield fetchBankAccount(merchantId);
          const bankAccounts = apiResultB.data || null;
          if (bankAccounts.success) {
            yield put({
              type: actions.FAVORITE_CHANGED,
              bankAccounts: bankAccounts.data,
            });
          } else {
            yield put({
              type: actions.FAVORITE_CHANGED,
            });
          }
        } else {
          yield put({
            type: actions.CHANGING_FAVORITE_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.CHANGING_FAVORITE_FAILED,
        });
      }
    }
  });
}
export function* changeStatus() {
  yield takeEvery('CHANGE_STATUS', function* ({ payload }) {
    const state = yield select();
    const merchantId = state.Auth.selectedMerchant;
    if (payload) {
      try {
        yield put({
          type: actions.CHANGING_STATUS,
        });
        const apiResult = yield changeStatusValue(payload);
        const result = apiResult.data || null;
        if (result.success) {
          const apiResultB = yield fetchBankAccount(merchantId);
          const bankAccounts = apiResultB.data || null;
          if (bankAccounts.success) {
            yield put({
              type: actions.STATUS_CHANGED,
              bankAccounts: bankAccounts.data,
            });
          } else {
            yield put({
              type: actions.STATUS_CHANGED,
            });
          }
        } else {
          yield put({
            type: actions.CHANGING_STATUS_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.CHANGING_STATUS_FAILED,
        });
      }
    }
  });
}
export function* downloadWithdrawal() {
  yield takeEvery('DOWNLOAD_WITHDRAWAL', function* ({ payload }) {
    // const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOAD_WITHDRAWAL_LOADING,
          payload: true,
        });
        const apiResult = yield downloadWithdrawalReport(payload);
        const result = apiResult.data || null;
        if (!result.success) {
          yield put({
            type: actions.DOWNLOAD_WITHDRAWAL_MESSAGE,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_WITHDRAWAL_ERROR,
        });
      }
    }
  });
}
export function* downloadSplitPayment() {
  yield takeEvery('DOWNLOAD_SPLIT', function* ({ payload }) {
    // const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOAD_SPLIT_LOADING,
          payload: true,
        });
        const apiResult = yield downloadSplitReport(payload);
        const result = apiResult.data || null;
        if (!result.success) {
          yield put({
            type: actions.DOWNLOAD_SPLIT_MESSAGE,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_SPLIT_ERROR,
        });
      }
    }
  });
}
export function* downloadTopup() {
  yield takeEvery('DOWNLOAD_TOPUP', function* ({ payload }) {
    // const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOAD_TOPUP_LOADING,
          payload: true,
        });
        const apiResult = yield downloadTopupReport(payload);
        const result = apiResult.data || null;
        if (!result.success) {
          yield put({
            type: actions.DOWNLOAD_TOPUP_MESSAGE,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_TOPUP_ERROR,
        });
      }
    }
  });
}
export function* downloadBizwalletReport() {
  yield takeEvery('DOWNLOAD_BIZWALLET_REPORT', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOADING_BIZWALLET_REPORT,
        });
        // ADD API CALL HERE - DOWNLOAD BIZWALLET REPORT
        const apiResult = yield downloadBizReport(payload);
        const result = apiResult.data || null;
        if (!result.success) {
          yield put({
            type: actions.DOWNLOAD_BIZWALLET_REPORT_ERROR,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_BIZWALLET_REPORT_ERROR,
          payload: 'Failed to download bizwallet report.'
        });
      }
    }
  });
}
export function* fetchwithdrawalRequest() {
  yield takeEvery('FETCH_WITHDRAWAL_REQUEST', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.FETCHING_WITHDRAWAL_REQUEST,
          payload: true,
        });
        const apiResult = yield withdrawalRequestingByReqId(payload);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.FETCH_WITHDRAWAL_REQUEST_SUCCESS,
            payload: result.data
          });
        } else {
          yield put({
            type: actions.FETCH_WITHDRAWAL_REQUEST_FAILED,
            payload: 0,
          });
        }
      } catch (error) {
        yield put({
          type: actions.FETCH_WITHDRAWAL_REQUEST_FAILED,
          payload: 0,
        });
      }
    }
  });
}
export function* updateWithdrawalStatus() {
  yield takeEvery('UPDATE_WITHDRAWAL_STATUS', function* ({ payload }) {
    const state = yield select();
    const withdrawalData = state.Financials.withdrawTransactionData;
    const pagination = state.Financials.withdrawTransactionPagination;
    if (payload) {
      try {
        yield put({
          type: actions.UPDATING_WITHDRAWAL_STATUS,
          payload: true,
        });
        const apiResult = yield updatingWithdrawalStatus(payload);
        const result = apiResult.data || null;
        if (result.success) {

          const foundIndex = withdrawalData.findIndex(x => x.requestId === payload.requestId);
          withdrawalData[foundIndex] = result.data;
          // REPLACE OLD WITHDRAWAL DATA WITH NEW
          // yield put({
          //   type: actions.GET_WITHDRAWAL_REQUEST_SUCCESS,
          //   payload: withdrawalData
          // });
          yield put({
            type: actions.GET_WITHDRAWAL_TRANSACTION_SUCCESS,
            payload: withdrawalData,
            pagination,
          });
          yield put({
            type: actions.UPDATING_WITHDRAWAL_STATUS,
            payload: false
          });
          yield put({
            type: actions.UPDATED_WITHDRAWAL_STATUS,
            payload: result.data
          });
        }
      } catch (error) {
        yield put({
          type: actions.UPDATING_FAILED_WITHDRAWAL_STATUS,
          payload: false,
        });
      }
    }
  });
}
export function* sendAutosweepOtp() {
  yield takeEvery('SEND_AUTOSWEEP_OTP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.SENDING_AUTOSWEEP_OTP,
        });
        const apiResult = yield sendOtpSms(payload);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.AUTOSWEEP_OTP_SENT,
            otpSent: true,
            otpMessage: result.message,
          });
        } else {
          yield put({
            type: actions.SENDING_AUTOSWEEP_OTP_FAILED,
            otpSent: false,
            otpMessage: result.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SENDING_AUTOSWEEP_OTP_FAILED,
          otpSent: false,
          otpMessage: 'Error on sending sms otp.',
        });
      }
    }
  });
}
export function* saveAutoSweep() {
  yield takeEvery('SAVE_AUTO_SWEEP', function* ({ payload, process }) {
    if (payload) {
      try {
        yield put({
          type: actions.SAVING_AUTO_SWEEP,
        });
        let apiResult;
        if (process === 'Add') {
          delete payload.id;
          apiResult = yield insertAutoSweep(payload);
        } else {
          apiResult = yield changeAutoSweepStatus(payload);
        }
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.SAVED_AUTO_SWEEP,
            result: result,
          });
        } else {
          yield put({
            type: actions.SAVING_AUTO_SWEEP_FAILED,
            message: result.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SAVING_AUTO_SWEEP_FAILED,
          message: error,
        });
      }
    }
  });
}
export function* walletTopUp() {
  yield takeEvery('WALLET_TOP_UP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.TOPPING_UP,
        });
        const apiResult = yield walletTopUpRequest(payload);
        const result = apiResult.data;
        if (result.success) {
          yield put({
            type: actions.WALLET_TOP_UP_SUCCESS,
            result: result.data,
          });
        } else {
          yield put({
            type: actions.WALLET_TOP_UP_FAILED,
            message: result.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.WALLET_TOP_UP_FAILED,
          message: 'Topup failed, cannot process transaction.',
        });
        yield cancel();
      }
    }
  });
}
export function* sendReportEmail() {
  yield takeEvery('SEND_REPORT_EMAIL', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.SENDING_REPORT_EMAIL,
        });
        let apiResult;
        if (!payload.isImporterExporter) {
          apiResult = yield sendingReportEmail(payload);
        } else {
          apiResult = yield sendingReportEmailImporterExporter(payload);
        }
        const result = apiResult.data;
        console.log('RESULT_BOC_REPORT', result);
        if (result.success) {
          yield put({
            type: actions.DONE_SENDING_REPORT_EMAIL,
            result: 'Success on Sending BOC Report, Please check your email!',
          });
        } else {
          yield put({
            type: actions.ERROR_REPORT_EMAIL,
            message: result.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.ERROR_REPORT_EMAIL,
          message: 'Sending boc report failed.',
        });
        yield cancel();
      }
    }
  });
}
export function* centinelTopUp() {
  yield takeEvery('CENTINEL_TOP_UP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.CENTINEL_TOPPING_UP,
        });
        const apiResult = yield centinelTopUpRequest(payload);
        const result = apiResult.data;
        if (result.success) {
          const apiResult = yield fetchBalance(payload.merchantId);
          const result = apiResult.data;
          if (result.success) {
            const apiResultB = yield fetchWithdrawableBalance(payload.merchantId);
            const resultB = apiResultB.data.withdrawalBalance || '0.00';
            if (apiResultB.data.success) {
              const walletDetails = {
                ...result.data,
                withdrawable: resultB,
              }
              yield put({
                type: actions.CENTINEL_TOP_UP_SUCCESS,
                walletDetails: walletDetails,
                result: result.data,
              });
            } else {
              yield put({
                type: actions.CENTINEL_TOP_UP_SUCCESS,
                walletDetails: null,
                result: result.data,
              });
            }
          }
        } else {
          yield put({
            type: actions.CENTINEL_TOP_UP_FAILED,
            message: result.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.CENTINEL_TOP_UP_FAILED,
          message: 'Topup uncessful, cannot process transaction.',
        });
      }
    }
  });
}
function resendingOtp(data) {
  return post(`withdrawsmsreqs/resend/otp-v2/`, data);
}
// function withdrawalRequesting() {
//   return get(`disbursementreqs/v-2/`);
// }
// function withdrawalRequestingNew(mid, role, selectedMerchant) {
//   if (role === 'Administrator' && selectedMerchant === 'All Merchant') {
//     // return get(`disbursementreqs/v-2/${selectedMerchant}`);
//     return get(`topuptransactions/get_wallet_transaction/${selectedMerchant}`);
//   } else if (role === 'Administrator') {
//     return get(`topuptransactions/get_wallet_transaction/${mid}`);
//   } else if (role === 'Merchant Admin') {
//     return get(`topuptransactions/get_wallet_transaction/${mid}`);
//   }
// }
function withdrawalTransactionRequest(data) {
  const newData = {
    mid: data.mid,
    page: data.page,
  }
  return get(`topuptransactions/get/withdraw_transaction/`, newData, data.page, null, null);
}
function splitTransactionRequest(data) {
  const newData = {
    mid: data.mid,
    page: data.page,
    // tenantId: data.tenantId,
  }
  return get(`topuptransactions/get/split_payment_transaction/`, newData, data.page, null, null);
}
function topupTransactionRequest(data) {
  const newData = {
    mid: data.mid,
    page: data.page,
    // tenantId: data.tenantId,
  }
  return get(`topuptransactions/get/topup_transaction/`, newData, data.page, null, null);
}
function onlinePaymentTransactionRequest(data) {
  const newData = {
    mid: data.mid,
    page: data.page,
    // tenantId: data.tenantId,
  }
  return get(`integration/v1/get/online_payments_transaction/`, newData, data.page, null, null);
}
function withdrawalRequestingByReqId(id) {
  return get(`disbursementreqs/v-2/get_request_id/${id}`);
}
function verifyingOtp(signature, data) {
  return post(`disbursementreqs/withdraw/${signature}`, data);
}
function verifyingOtpCorporate(data) {
  return post(`disbursementreqs/withdraw_corporate`, data);
}
function fetchBalance(mid) {
  return get(`merchantwallets/get_by_merchant_id/${mid}`);
}
function requestingOtp(data) {
  return post(`withdrawsmsreqs/request_otp`, data);
}
function fetchWithdrawableBalance(mid) {
  return get(`integration/v1/get_balance/${mid}`);
}
function fetchBankAccount(mid) {
  return get(`merchantwithdrawalaccounts/fetch_by_mid/${mid}`);
}
function getLimit(mid) {
  return get(`payment_transactions/get_aggregated_report/${mid}`);
}
function getSupportedBanks() {
  return get(`supportedbanks/get_all_supported_banks`);
}
function sendOtpSms(payload) {
  return post(`withdrawsmsreqs/`, payload);
}
function insertBankAccount(payload) {
  return post(`merchantwithdrawalaccounts/`, payload);
}
function editBankAccount(payload) {
  return PUT(`merchantwithdrawalaccounts/update_bank_account/${payload.Id}`, payload);
}
function deleteBankAccount(id) {
  return PUT(`merchantwithdrawalaccounts/delete/${id}`);
}
function changeFavoriteValue(payload) {
  return PUT(`merchantwithdrawalaccounts/change_favorite/${payload.id}`, payload);
}
function changeStatusValue(payload) {
  return PUT(`merchantwithdrawalaccounts/change_status/${payload.id}`, payload);
}
function resendOtpSms(payload) {
  return post(`withdrawsmsreqs/resend/otp/`, payload);
}
function downloadWithdrawalReport(payload) {
  return post(`downloadtransactions/withdrawal_request_ui/`, payload);
}
function downloadSplitReport(payload) {
  return post(`downloadtransactions/split_payment_request_ui/`, payload);
}
function downloadTopupReport(payload) {
  return post(`downloadtransactions/topup_request_ui/`, payload);
}
function updatingWithdrawalStatus(payload) {
  return post(`disbursementreqs/update_withdrawal_status/`, payload);
}
function insertAutoSweep(payload) {
  return post(`autosweeps/`, payload);
}
function changeAutoSweepStatus(payload) {
  return post(`autosweeps/change_status`, payload);
}
function walletTopUpRequest(payload) {
  return post(`topuptransactions/`, payload);
}
function centinelTopUpRequest(payload) {
  return post(`topuptransactions/centinel_topup`, payload);
}
function downloadBizReport(payload) {
  return post(`downloadtransactions/download_biz_wallet_report`, payload);
}
function sendingReportEmail(payload) {
  return post(`formreferences/generate_report_boc`, payload);
}
function sendingReportEmailImporterExporter(payload) {
  return post(`formreferences/generate_report_importer_exporter`, payload);
}
export default function* rootSaga() {
  yield all([
    fork(loadBalance),
    fork(loadBankAccounts),
    fork(requestOtp),
    fork(verifyOtp),
    fork(loadSupportedBanks),
    fork(sendSmsOtp),
    fork(saveBankAccount),
    fork(resendOtp),
    fork(wResendOtp),
    fork(updateBankAccount),
    fork(removeBankAccount),
    fork(changeFavorite),
    fork(withdrawalTransaction),
    fork(splitTransaction),
    fork(topupTransaction),
    fork(getOnlinePaymentTransaction),
    fork(downloadWithdrawal),
    fork(downloadSplitPayment),
    fork(downloadTopup),
    fork(fetchwithdrawalRequest),
    fork(updateWithdrawalStatus),
    fork(changeStatus),
    fork(sendAutosweepOtp),
    fork(saveAutoSweep),
    fork(walletTopUp),
    fork(downloadBizwalletReport),
    fork(centinelTopUp),
    fork(sendReportEmail),
  ]);
}