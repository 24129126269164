import actions from './actions';

const initState = {
  authManagementRole: null,
  loadingAuthManagementRole: false,
  authManagementRoleSaved: false,
  rolePermission: null,
  payloadCore: null,
  selectedId: null,

  openRolePermissionDialog: false,
  statusData: null,
  addAuthRoleSuccess: false,

  minMaxAmount: null,
  fetchingMinMaxAmount: false,
  fetchMinMaxAmountFailed: null,

};

export default (state = initState, action) => {
  switch (action.type) {

    case actions.ADDING_AUTH_ROLE:
      return {
        ...state,
        authManagementRole: null,
        loadingAuthManagementRole: true,
      };
    case actions.AUTH_MANAGEMENT_ROLE_FETCHED:
      return {
        ...state,
        authManagementRole: action.payload,
        loadingAuthManagementRole: false,
      };
    case actions.AUTH_MANAGEMENT_SAVED:
      return {
        ...state,
        authManagementRoleSaved: true,
      };
    case actions.RESET_SAVE_AUTH_MANAGEMENT:
      return {
        ...state,
        authManagementRoleSaved: false,
      };

    case actions.OPEN_ROLE_PERMISSION_DIALOG:
      return {
        ...state,
        openRolePermissionDialog: !state.openRolePermissionDialog,
      };

    case actions.ROLE_PERMISSION_FETCHED:
      return {
        ...state,
        rolePermission: action.payload,
        payloadCore: action.payloadCore,
        selectedId: action.selectedId,
      };

    case actions.RESET_ROLE_PERMISSION_DATA:
      return {
        ...state,
        rolePermission: null,
        selectedId: null,
      };

    case actions.STATUS_FETCHED:
      return {
        ...state,
        statusData: action.payload,
      };
    case actions.AUTH_ROLE_ADDED:
      return {
        ...state,
        addAuthRoleSuccess: true,
      };
    case actions.RESET_ADD_AUTH_ROLE:
      return {
        ...state,
        addAuthRoleSuccess: false,
      };
    case actions.FETCHED_MIN_MAX_AMOUNT:
      return {
        ...state,
        minMaxAmount: action.payload,
        fetchingMinMaxAmount: false,
        fetchMinMaxAmountFailed: null,
      };
    case actions.FETCHING_MIN_MAX_AMOUNT:
      return {
        ...state,
        fetchingMinMaxAmount: true,
      };
    case actions.FETCH_MIN_MAX_AMOUNT_FAILED:
      return {
        ...state,
        fetchMinMaxAmountFailed: action.payload,
        minMaxAmount: null,
        fetchingMinMaxAmount: false,
      };
    default:
      return state;
  }
}
