import actions from './actions';

const initState = {
  merchantLoading: false,
  merchantInfo: null,
  merchantErrorMessage: null,
  merchantLogo: null,
  loadingPchannel: false,
  merchantPchannel: null,
  bankAccount: null,
  // MERCHANT USERS
  merchantUsers: null,
  merchantUsersPagination: null,
  selectedMerchantUser: null,
  merchantUserUpdatedData: null,
  addMechantUserError: null,
  addMechantUserSuccess: false,
  editMechantUserSuccess: false,
  addingMerchant: false,
  loadingFilteredRole: false,
  filteredRole: null,
  payloadCore: null,
  selectedId: null,
  rolePermission: null,
  openRolePermissionDialog: false,
  updatingMerchantData: false,
  updateMerchantError: null,
  merchantUserDeleted: false,
  merchantUserDeleting: false,
  merchantUserDeleteMessage: null,
  merchantUserPasswordReset: false,
  merchantUserPasswordResetting: false,
  merchantUserPasswordResetMessage: null,
  fetchingMerchantUsers: false,
  loadingMoreMerchantUsers: false,
  allPchannels: null,
  updatingMerchantDetails: false,
  updatedMerchantDetails: false,
  updatedMessage: null,
  merchantStores: null,
  downloadingHistory: false,
  downloadMessage: null,
  downloadData: null,
  downloadingStoreTxn: false,
  downloadStoreTxnMessage: null,
  downloadTransaction: null,
  downloadStoreId: null,
  riskProfileData: null,
  gettingKyc: false,
  kycQualified: null,
  kycApplication: null,
  kycDocs: [],
  kycRequirements: [],
  postingKyc: false,
  postedKyc: false,
  previewingKycDocs: false,
  previewedKycDocs: null,
  mqttResult: false,
  referralLinkdata: null,
  merchLogo: [],
  uploadingLogo: false,
  uploadedLogo: false,
  uploadLogoMessage: null,
  reuploadAsking: false,
  reuploadAsked: false,
  reuploadAskingResult: null,
  approvingKycDocs: false,
  approvedKycDocs: false,
  approvingResult: null,
  updatingMerchantChannels: false,
  updatedMerchantChannels: false,
  updatedMerchChannelMsg: null,
  redirectFetchingImg: false,
  redirectImg: null,
  redirectStoreChannels: null,
  redirectGrabToken: null,
  redirectErrorMessage: null,
  redirectFetchingData: false,
  redirectCustomerData: null,
  redirectPaymentRequesting: false,
  redirectPaymentRequested: null,
  redirectPaymentMessage: null,
  redirectPaymentSuccess: false,
  resettingMerchantUserPassword: false,
  merchantPasswordResetData: null,
  newMerchantUserPasswordReset: false,
  resettingTokenData: false,
  hasResetTokenData: null,
  redirectStoreInfo: null,
  redirectMonthlyLimit: null,
  redirectReachLimit: null,
  redirectStoreTemplate: null,
  mqttKycQualified: null,
  mqttKycApplication: null,
  mqttKycDocs: [],
  resetErrorMessage: null,
  resetError: false,
  fetchingSelectedMerchantSettings: false,
  selectedMerchantSettings: null,
  fetchingOTPStatus: true,
  otpStatusMerchantSettings: null,
  otpMessageMerchantSettings: null,
  updatingMerchantSettings: false,
  updatedMerchantSettings: false,
  webstoreData: null,
  webstoreDataUpdating: false,
  webstoreDataUpdated: false,
  webstoreDataSuccess: null,
  webstoreCatalogUpdated: false,
  webstoreCatalogUpdating: false,
  webstoreUpdateErrorMsg: null,
  webstoreUploadingBanner: false,
  webstoreUploadingBannerErrorMsg: null,
  provinceData: null,
  fetchingProvinceData: false,
  hasUpdatedShippingSetup: false,
  updatingShippingSetup: false,
  updatingShippingSetupFailed: false,
  addingDepartment: false,
  addDepartmentSuccess: false,
  addDepartmentFailed: false,
  addDepartmentMessage: null
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.LOAD_MERCHANT:
      return initState;
    case actions.LOADING_MERCHANT_INFO:
      return {
        ...state,
        merchantLoading: true,
        merchantInfo: null,
      };
    case actions.MERCHANT_INFO_LOADED:
      return {
        ...state,
        merchantLoading: false,
        merchantInfo: action.merchantInfo,
        merchantLogo: action.merchantLogo,
        bankAccount: action.bankAccount,
      };
    case actions.LOAD_MERCHANT_INFO_FAILED:
      return {
        ...state,
        merchantLoading: false,
        merchantInfo: null,
        merchantErrorMessage: '', // payload err here
      };
    //PCHANNEL
    // case actions.LOAD_MERCHANT_PCHANNEL:
    //   return initState;
    case actions.LOADING_MERCHANT_PCHANNEL:
      return {
        ...state,
        loadingPchannel: true,
      };
    case actions.MERCHANT_PCHANNEL_LOADED:
      return {
        ...state,
        loadingPchannel: false,
        merchantPchannel: action.merchantPchannel,
      };
    case actions.LOAD_MERCHANT_PCHANNEL_FAILED:
      return {
        ...state,
        loadingPchannel: false,
        merchantPchannel: null,
      };
    case actions.MERCHANT_USERS_FETCHED:
      return {
        ...state,
        merchantUsers: action.merchantUsersData,
        merchantUsersPagination: action.pagination,
        fetchingMerchantUsers: false,
        loadingMoreMerchantUsers: false,
      };
    case actions.FETCHING_MERCHANT_USERS:
      return {
        ...state,
        fetchingMerchantUsers: true,
        selectedMerchantUser: null,
        merchantUsers: null,
      };
    case actions.LOADING_MORE_MERCHANT_USERS:
      return {
        ...state,
        loadingMoreMerchantUsers: true,
      };
    case actions.SET_MERCHANT_USER_DATA:
      return {
        ...state,
        selectedMerchantUser: action.payload,
      };
    case actions.ADD_MERCHANT_USER_FAILED:
      return {
        ...state,
        addMechantUserError: action.payload,
        addingMerchant: false,
        addMechantUserSuccess: false,
      };
    case actions.MERCHANT_USER_ADDED:
      return {
        ...state,
        addingMerchant: false,
        addMechantUserSuccess: true,
      };
    case actions.FILTERED_ROLE_FETCHED:
      return {
        ...state,
        filteredRole: action.payload,
        loadingFilteredRole: false,
      };
    case actions.ROLE_PERMISSION_FETCHED:
      return {
        ...state,
        rolePermission: action.payload,
        payloadCore: action.payloadCore,
        selectedId: action.selectedId,
      };
    // case actions.OPEN_ROLE_PERMISSION_DIALOG:
    //   return {
    //     ...state,
    //     openRolePermissionDialog: !state.openRolePermissionDialog,
    //   };
    case actions.RESET_ROLE_PERMISSION_DATA:
      return {
        ...state,
        rolePermission: null,
        selectedId: null,
      };
    case actions.FETCHING_ALL_FILTERED_ROLE:
      return {
        ...state,
        loadingFilteredRole: true,
      };
    case actions.RESET_ADD_USER_FORM:
      return {
        ...state,
        addingMerchant: false,
        addMechantUserSuccess: false,
        addMechantUserError: null,
      };
    case actions.UPDATING_MERCHANT_USER:
      return {
        ...state,
        updatingMerchantData: true,
      };
    case actions.RESET_EDIT_USER_FORM:
      return {
        ...state,
        editUserForm: false,
        editMechantUserSuccess: false,
        updateMerchantError: null,
      };
    case actions.ADDING_MERCHANT_USER:
      return {
        ...state,
        addingMerchant: true,
      };
    case actions.UPDATE_MERCHANT_USER_FAILED:
      return {
        ...state,
        updateMerchantError: action.updateError,
        editMechantUserSuccess: false,
      };
    case actions.UPDATE_MERCHANT_USER_SUCCESS:
      return {
        ...state,
        editMechantUserSuccess: true,
        updatingMerchantData: false,
      };
    case actions.DEACTIVATING_MERCHANT_USER:
      return {
        ...state,
        merchantUserDeleted: false,
        merchantUserDeleting: true,
      };
    case actions.MERCHANT_USER_DEACTIVATED:
      return {
        ...state,
        merchantUserDeleted: true,
        merchantUserDeleting: false,
        merchantUserDeleteMessage: `Portal User deleted!`,
      };
    case actions.DEACTIVATE_MERCHANT_USER_FAILED:
      return {
        ...state,
        merchantUserDeleted: false,
        merchantUserDeleting: false,
        merchantUserDeleteMessage: `Couldn't delete user. Please contact Administrator`,
      };
    case actions.DEACTIVATE_MERCHANT_USER_RESET:
      return {
        ...state,
        merchantUserDeleted: false,
        merchantUserDeleting: false,
        merchantUserDeleteMessage: null,
      };
    case actions.RESETTING_MERCHANT_USER_PASSWORD:
      return {
        ...state,
        merchantUserPasswordResetting: true,
      };
    case actions.MERCHANT_USER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        merchantUserPasswordReset: true,
        merchantUserPasswordResetting: false,
        merchantUserPasswordResetMessage: 'Portal User Password Reset!',
      };
    case actions.MERCHANT_USER_PASSWORD_RESET_FAILED:
      return {
        ...state,
        merchantUserPasswordResetMessage: `Couldn't reset password. Please contact Administrator`,
        merchantUserPasswordResetting: false,
        merchantUserPasswordReset: false,
      };
    case actions.RESET_MERCHANT_USER_PASSWORD_RESET:
      return {
        ...state,
        merchantUserPasswordReset: false,
        merchantUserPasswordResetting: false,
        merchantUserPasswordResetMessage: null,
      };
    case actions.MERCHANT_USER_UPDATED:
      const updatedUser = action.merchantUserUpdatedData;
      const merchantUsersList = state.merchantUsers;
      let updatedSelectedUser;
      for (let i = 0; i < merchantUsersList.length; i++) {
        const element = merchantUsersList[i];
        if (updatedUser.Id === element.Id) {
          merchantUsersList[i].firstName = updatedUser.firstName;
          merchantUsersList[i].lastName = updatedUser.lastName;
          merchantUsersList[i].email = updatedUser.email;
          updatedSelectedUser = merchantUsersList[i];
        }
      }
      Object.assign(state.selectedMerchantUser, updatedSelectedUser);
      return {
        ...state,
        merchantUsers: merchantUsersList,
        selectedMerchantUser: {
          ...state.selectedMerchantUser,
          updatedSelectedUser,
        },
      };
    case actions.GETTING_ALL_PCHANNELS:
      return {
        ...state,
        allPchannels: null,
      };
    case actions.GET_ALL_PCHANNELS_SUCCESS:
      return {
        ...state,
        allPchannels: action.payload,
      };
    case actions.GET_ALL_PCHANNELS_FAILED:
      return {
        ...state,
        allPchannels: null,
      };
    case actions.EDITING_MERCHANT_DETAILS:
      return {
        ...state,
        updatingMerchantDetails: true,
      };
    case actions.MERCHANT_DETAILS_EDITED:
      return {
        ...state,
        updatingMerchantDetails: false,
        updatedMerchantDetails: true,
        updatedMessage: 'Successfully updated merchant details',
        merchantInfo: action.result.merchantDetails,
      };
    case actions.EDIT_MERCHANT_DETAILS_FAILED:
      return {
        ...state,
        updatingMerchantDetails: false,
        updatedMerchantDetails: true,
        updatedMessage: 'Failed to update merchant details',
      };
    case actions.RESET_MESSAGE_NOTIF:
      return {
        ...state,
        updatingMerchantDetails: false,
        updatedMerchantDetails: false,
        updatedMessage: null,
      };
    case actions.DOWNLOADING_HISTORY:
      return {
        ...state,
        downloadingHistory: true,
      };
    case actions.DOWNLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        downloadingHistory: false,
        downloadData: action.data,
      };
    case actions.RESET_DOWNLOAD_HISTORY:
      return {
        ...state,
        downloadingHistory: false,
        downloadData: null,
        downloadMessage: null,
      };
    case actions.DOWNLOAD_HISTORY_FAILED:
      return {
        ...state,
        downloadingHistory: false,
        downloadMessage: action.message,
      };
    // DOWNLOAD STORE TXN
    case actions.DOWNLOADING_STORE_TXN:
      return {
        ...state,
        downloadingStoreTxn: true,
        downloadStoreId: action.result,
      };
    case actions.DOWNLOAD_STORE_TXN_SUCCESS:
      return {
        ...state,
        downloadingStoreTxn: false,
        downloadTransaction: action.data,
      };
    case actions.RESET_DOWNLOADED_STORE_TXN:
      return {
        ...state,
        downloadingStoreTxn: false,
        downloadTransaction: null,
        downloadStoreTxnMessage: null,
        downloadStoreId: null,
      };
    case actions.DOWNLOAD_STORE_TXN_FAILED:
      return {
        ...state,
        downloadingStoreTxn: false,
        downloadStoreTxnMessage: action.message,
      };
    case actions.FETCHING_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        riskProfileData: action.riskProfileData,
      };
    case actions.GETTING_KYC_QUALIFIED:
      return {
        ...state,
        gettingKyc: true,
        kycDocs: [],
        kycRequirements: [],
        kycQualified: null,
        kycApplication: null,
      };
    case actions.GOT_KYC_QUALIFIED:
      return {
        ...state,
        gettingKyc: false,
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
        kycRequirements: action.requirements,
      };
    case actions.GETTING_KYC_QUALIFIED_FAILED:
      return {
        ...state,
        gettingKyc: false,
      };
    case actions.POSTING_KYC_DOCS:
      return {
        ...state,
        postingKyc: true,
      };
    case actions.UPDATED_KYC_DOCS:
      return {
        ...state,
        postingKyc: false,
      };
    case actions.POSTED_KYC_DOCS:
      return {
        ...state,
        postingKyc: false,
        postedKyc: true,
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
        mqttResult: false,
      };
    case actions.MQTT_POSTING_RESULT:
      return {
        ...state,
        mqttResult: true,
      };
    case actions.RESET_KYC_POST:
      return {
        ...state,
        postingKyc: false,
        postedKyc: false,
        mqttResult: false,
      };
    case actions.POSTING_KYC_DOCS_FAILED:
      return {
        ...state,
        postingKyc: false,
        postedKyc: false,
        mqttResult: false,
      };
    case actions.PREVIEWING_KYC_DOCS:
      return {
        ...state,
        previewingKycDocs: true,
      };
    case actions.PREVIEWED_KYC_DOCS:
      return {
        ...state,
        previewingKycDocs: false,
        previewedKycDocs: action.data,
      };
    case actions.PREVIEWING_KYC_DOCS_FAILED:
      return {
        ...state,
        previewingKycDocs: false,
      };
    case actions.RESET_KYC_PREVIEW:
      return {
        ...state,
        previewingKycDocs: false,
        previewedKycDocs: null,
      };
    case actions.FETCH_MERCHANT_REFERRAL_LINK_SUCCESS:
      return {
        ...state,
        referralLinkdata: action.referralLinkdata,
      };
    // UPLOAD LOGO
    case actions.UPLOADING_MERCHANT_LOGO:
      return {
        ...state,
        uploadingLogo: true,
      };
    case actions.UPLOADED_MERCHANT_LOGO:
      return {
        ...state,
        uploadingLogo: false,
        uploadedLogo: true,
        merchLogo: action.data ? action.data.merchLogo : [],
        // merchantLogo: action.merchantLogo,
        uploadLogoMessage: action.message,
      };
    case actions.RESET_MERCHANT_LOGO_UPLOAD:
      return {
        ...state,
        uploadingLogo: false,
        uploadedLogo: false,
        uploadLogoMessage: null,
      };
    case actions.UPLOAD_MERCHANT_LOGO_FAILED:
      return {
        ...state,
        uploadingLogo: false,
        uploadedLogo: false,
        uploadLogoMessage: action.message,
      };
    case actions.ASKING_FOR_REUPLOAD:
      return {
        ...state,
        reuploadAsking: true,
      };
    case actions.ASKED_FOR_REUPLOAD:
      return {
        ...state,
        reuploadAsking: false,
        reuploadAsked: true,
        reuploadAskingResult: 'Reupload request sent',
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
      };
    case actions.ASKING_FOR_REUPLOAD_FAILED:
      return {
        ...state,
        reuploadAsking: false,
        reuploadAsked: true,
      };
    case actions.RESET_ASK_REUPLOAD:
      return {
        ...state,
        reuploadAsking: false,
        reuploadAsked: false,
        reuploadAskingResult: null,
      };
    case actions.APPROVING_KYC_DOCS:
      return {
        ...state,
        approvingKycDocs: true,
      };
    case actions.APPROVED_KYC_DOCS:
      return {
        ...state,
        approvingKycDocs: false,
        approvedKycDocs: true,
        approvingResult: 'KYC documents approved',
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
      };
    case actions.APPROVING_KYC_DOCS_FAILED:
      return {
        ...state,
        approvingKycDocs: false,
        approvedKycDocs: true,
      };
    case actions.RESET_APPROVING:
      return {
        ...state,
        approvingKycDocs: false,
        approvedKycDocs: false,
        approvingResult: null,
      };
    // UPDATE MERCHANT CHANNELS
    case actions.UPDATING_MERCHANT_CHANNELS:
      return {
        ...state,
        updatingMerchantChannels: true,
      };
    case actions.MERCHANT_CHANNELS_UPDATED:
      return {
        ...state,
        merchantPchannel: action.result,
        updatingMerchantChannels: false,
        updatedMerchantChannels: true,
        updatedMerchChannelMsg: action.message,
        // merchantInfo: action.result.merchantDetails,
      };
    case actions.UPDATE_MERCHANT_CHANNELS_FAILED:
      return {
        ...state,
        updatingMerchantChannels: false,
        updatedMerchantChannels: true,
        updatedMerchChannelMsg: action.message,
      };
    case actions.RESET_UPDATE_MERCHANT_CHANNELS:
      return {
        ...state,
        updatingMerchantChannels: false,
        updatedMerchantChannels: false,
        updatedMerchChannelMsg: null,
      };
    case actions.FETCHING_MERCHANT_IMAGE:
      return {
        ...state,
        redirectFetchingImg: true,
      };
    case actions.FETCHED_MERCHANT_IMAGE:
      return {
        ...state,
        redirectFetchingImg: false,
        redirectImg: action.merchantLogo,
        redirectStoreChannels: action.storeChannels,
        redirectGrabToken: action.grabToken,
        redirectStoreInfo: action.storeInfo,
        redirectMonthlyLimit: action.monthlyLimit,
        redirectReachLimit: action.reachLimit,
        redirectStoreTemplate: action.storeTemplate
      };
    case actions.FETCH_MERCHANT_IMAGE_FAILED:
      return {
        ...state,
        redirectFetchingImg: false,
        redirectImg: null,
        redirectStoreChannels: null,
        redirectGrabToken: null,
        redirectErrorMessage: action.payload,
        redirectStoreInfo: null,
        redirectMonthlyLimit: null,
        redirectReachLimit: null,
        redirectStoreTemplate: null
      };
    case actions.FETCHING_CUSTOMER_DATA:
      return {
        ...state,
        redirectFetchingData: true,
      };
    case actions.FETCHED_CUSTOMER_DATA:
      return {
        ...state,
        redirectFetchingData: false,
        redirectCustomerData: action.payload,
      };
    case actions.FETCH_CUSTOMER_DATA_FAILED:
      return {
        ...state,
        redirectFetchingData: false,
      };
    case actions.PAYMENT_TRANSACTION_REQUESTING:
      return {
        ...state,
        redirectPaymentRequesting: true,
      };
    case actions.PAYMENT_TRANSACTION_REQUESTED:
      return {
        ...state,
        redirectPaymentRequesting: false,
        redirectPaymentRequested: action.payload,
        redirectPaymentSuccess: true,
      };
    case actions.PAYMENT_TRANSACTION_REQUEST_FAILED:
      return {
        ...state,
        redirectPaymentRequesting: false,
        redirectPaymentMessage: action.payload,
        redirectPaymentSuccess: false,
      };
    case actions.RESET_PAYMENT_TRANSACTION_REQUEST:
      return {
        ...state,
        redirectPaymentRequesting: false,
        redirectPaymentMessage: null,
        redirectPaymentRequested: null,
        redirectPaymentSuccess: false,
      };
    case actions.RESETTING_TOKEN_DATA:
      return {
        ...state,
        resettingTokenData: true,
      };
    case actions.RESET_TOKEN_DATA_SUCCESS:
      return {
        ...state,
        resettingTokenData: false,
        hasResetTokenData: action.result,
      };
    case actions.RESET_TOKEN_DATA_FAILED:
      return {
        ...state,
        resettingTokenData: false,
        hasResetTokenData: null,
      };
    case actions.NEW_RESETTING_MERCHANT_USER_PASSWORD:
      return {
        ...state,
        resettingMerchantUserPassword: true,
      };
    case actions.NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        merchantPasswordResetData: action.result,
        newMerchantUserPasswordReset: true,
      };
    case actions.NEW_RESET_MERCHANT_USER_PASSWORD_RESET:
      return {
        ...state,
        newMerchantUserPasswordReset: true,
      };
    case actions.NEW_MERCHANT_USER_PASSWORD_RESET_FAILED:
      return {
        ...state,
        resetErrorMessage: `Couldn't reset password. Please contact Administrator`,
        resetError: true,
        resettingMerchantUserPassword: false,
        newMerchantUserPasswordReset: false,
      };
    case actions.RESET_ERROR_MESSAGE:
      return {
        ...state,
        resetErrorMessage: action.payload,
        resetError: action.isError,
      };
    case actions.KYC_MQTT:
      return {
        ...state,
        mqttKycQualified: action.kycQualified ? action.kycQualified : null,
        mqttKycApplication: action.kycApplication ? action.kycApplication : null,
        mqttKycDocs: action.kycDocs ? action.kycDocs : [],
      };
    case actions.RESET_KYC_MQTT:
      return {
        ...state,
        mqttKycQualified: null,
        mqttKycApplication: null,
        mqttKycDocs: [],
      };
    case actions.FETCHING_MERCHANT_SETTINGS:
      return {
        ...state,
        fetchingSelectedMerchantSettings: true,
      };
    case actions.FETCHED_MERCHANT_SETTINGS:
      return {
        ...state,
        fetchingSelectedMerchantSettings: false,
        selectedMerchantSettings: action.data,
      };
    case actions.SENDING_OTP:
      // const newData = action.data;
      return {
        ...state,
        fetchingOTPStatus: true,
      };
    case actions.SEND_OTP_SUCCESS:
      // const newData = action.data;
      return {
        ...state,
        otpStatusMerchantSettings: 'Success',
        fetchingOTPStatus: false,
      };
    case actions.SEND_OTP_FAILED:
      // const newData = action.data;
      return {
        ...state,
        otpStatusMerchantSettings: 'Failed',
        fetchingOTPStatus: false,
      };
    case actions.SEND_OTP_RESET:
      // const newData = action.data;
      return {
        ...state,
        otpStatusMerchantSettings: null,
        fetchingOTPStatus: false,
      };
    case actions.UPDATING_MERCHANT_SETTINGS:
      // const newData = action.data;
      return {
        ...state,
        otpMessageMerchantSettings: null,
        updatingMerchantSettings: true,
      };
    case actions.UPDATED_MERCHANT_SETTINGS:
      // const newData = action.data;
      return {
        ...state,
        updatedMerchantSettings: true,
        merchantInfo: action.data,
        updatingMerchantSettings: false,
      };
    case actions.UPDATING_MERCHANT_SETTINGS_FAILED:
      return {
        ...state,
        updatedMerchantSettings: false,
        otpMessageMerchantSettings: action.message,
        updatingMerchantSettings: false,
      };
    case actions.UPDATING_MERCHANT_SETTINGS_ERROR:
      return {
        ...state,
        updatedMerchantSettings: false,
        otpMessageMerchantSettings: action.message,
        updatingMerchantSettings: false,
      };
    case actions.RESET_UPDATE_MERCHANT_SETTINGS:
      return {
        ...state,
        updatedMerchantSettings: false,
        otpMessageMerchantSettings: null,
      };
    case actions.FETCHING_MERCHANT_SETTINGS_FAILED:
      return {
        ...state,
        fetchingSelectedMerchantSettings: false,
        selectedMerchantSettings: null,
      };
    // FETCH STORE DATA
    case actions.FETCH_WEBSTORE_DATA_SUCCESS:
      return {
        ...state,
        webstoreData: action.data,
      };
    case actions.FETCH_WEBSTORE_DATA_FAILED:
      return {
        ...state,
        webstoreData: null,
      };
    // SAVE STORE DATA
    case actions.SAVE_WEBSTORE_DATA_SUCCESS:
      return {
        ...state,
        webstoreData: action.data,
      };
    case actions.SAVE_WEBSTORE_DATA_FAILED:
      return {
        ...state,
        webstoreData: null,
      };
    // UPLOAD STORE BANNER
    case actions.UPLOADING_WEBSTORE_BANNER:
      return {
        ...state,
        webstoreUploadingBanner: true,
      };
    case actions.UPLOAD_WEBSTORE_BANNER_SUCCESS:
      return {
        ...state,
        webstoreData: {
          ...state.webstoreData,
          bannerPhoto: action.data.bannerPhoto,
          ogBanner: action.data.ogBanner,
        },
        webstoreUploadingBanner: false,
      };
    case actions.UPLOAD_WEBSTORE_BANNER_FAILED:
      return {
        ...state,
        webstoreUploadingBanner: false,
        webstoreUploadingBannerErrorMsg: action.message,
      };
    // UPDATE STORE DATA
    case actions.UPDATING_WEBSTORE_DATA:
      return {
        ...state,
        webstoreDataUpdating: true,
        webstoreDataSuccess: null,
      };
    case actions.UPDATE_WEBSTORE_DATA_SUCCESS:
      return {
        ...state,
        webstoreData: action.data,
        webstoreDataSuccess: action.data,
        webstoreDataUpdated: true,
        webstoreDataUpdating: false,
      };
    case actions.RESET_WEBSTORE_DATA:
      return {
        ...state,
        webstoreDataUpdated: false,
        webstoreDataSuccess: null,
        webstoreUpdateErrorMsg: null,
      };
    case actions.UPDATE_WEBSTORE_DATA_FAILED:
      return {
        ...state,
        webstoreUpdateErrorMsg: action.message,
        webstoreDataUpdating: false,
      };

    // WEBSTORE CATALOG

    case actions.UPDATING_WEBSTORE_CATALOG:
      return {
        ...state,
        webstoreCatalogUpdating: true,
      };
    case actions.UPDATE_WEBSTORE_CATALOG_SUCCESS:
      return {
        ...state,
        webstoreData: action.data,
        webstoreCatalogUpdated: true,
        webstoreCatalogUpdating: false,
      };

    case actions.UPDATE_WEBSTORE_CATALOG_FAILED:
      return {
        ...state,
        webstoreCatalogUpdated: false,
        webstoreCatalogUpdating: false,
      };
    case actions.RESET_WEBSTORE_CATALOG:
      return {
        ...state,
        webstoreCatalogUpdated: false,
        webstoreCatalogUpdating: false,
        // FETCH PROVINCE DATA
      }
    case actions.FETCHING_PROVINCE:
      return {
        ...state,
        fetchingProvinceData: true,
      };
    case actions.FETCH_PROVINCE_SUCCESS:
      return {
        ...state,
        provinceData: action.provinces,
        fetchingProvinceData: false,
      };
    case actions.FETCH_PROVINCE_RESET:
      return {
        ...state,
        fetchingProvinceData: false,
      };
    case actions.FETCH_PROVINCE_FAILED:
      return {
        ...state,
        // provinceData: action.message,
        fetchingProvinceData: false,
      };
    // UPDATE SHIPPING SETUP
    case actions.UPDATING_SHIPPING_SETUP:
      return {
        ...state,
        updatingShippingSetup: true,
      };
    case actions.UPDATE_SHIPPING_SETUP_SUCCESS:
      return {
        ...state,
        provinceData: action.provinces,
        updatingShippingSetup: false,
        hasUpdatedShippingSetup: true,
      };
    case actions.UPDATE_SHIPPING_SETUP_RESET:
      return {
        ...state,
        updatingShippingSetup: false,
        hasUpdatedShippingSetup: false,
        updatingShippingSetupFailed: false,
      };
    case actions.UPDATE_SHIPPING_SETUP_FAILED:
      return {
        ...state,
        // provinceData: action.message,
        updatingShippingSetup: false,
        updatingShippingSetupFailed: true,
      };
    case actions.SENDING_EMAIL_LIMIT_WARNING:
      return {
        ...state,
        sendingEmail: true,
      };
    case actions.SENT_EMAIL_LIMIT_WARNING:
      return {
        ...state,
        // provinceData: action.message,
        sendEmailStatus: action.payload,
        sendingEmail: false,
      };
    case actions.SEND_EMAIL_LIMIT_WARNING_FAILED:
      return {
        ...state,
        sendingEmail: false,
      };
    case actions.ADDING_DEPARTMENT:
      return {
        ...state,
        addingDepartment: true,
      };
    case actions.ADDED_DEPARTMENT:
      return {
        ...state,
        addingDepartment: false,
        addDepartmentSuccess: true,
        addDepartmentFailed: false,
        addDepartmentMessage: action.message,
      };
    case actions.ADD_DEPARTMENT_FAILED:
      return {
        ...state,
        addingDepartment: false,
        addDepartmentSuccess: false,
        addDepartmentFailed: true,
        addDepartmentMessage: action.message,
      };
    case actions.RESET_ADD_DEPARTMENT:
      return {
        ...state,
        addingDepartment: false,
        addDepartmentSuccess: false,
        addDepartmentFailed: false,
        addDepartmentMessage: null,
      };
    default:
      return state;
  }
}
