const actions = {

  // FETCH AUTH MANAGMENT ROLE
  FETCH_AUTH_MANAGEMENT_ROLE: 'FETCH_AUTH_MANAGEMENT_ROLE',
  FETCHING_AUTH_MANAGEMENT_ROLE: 'FETCHING_AUTH_MANAGEMENT_ROLE',
  AUTH_MANAGEMENT_ROLE_FETCHED: 'AUTH_MANAGEMENT_ROLE_FETCHED',
  FETCH_AUTH_MANAGEMENT_ROLE_FAILED: 'FETCH_AUTH_MANAGEMENT_ROLE_FAILED',
  // SAVE AUTH MANAGEMENT
  SAVE_AUTH_MANAGEMENT: 'SAVE_AUTH_MANAGEMENT',
  SAVING_AUTH_MANAGEMENT: 'SAVING_AUTH_MANAGEMENT',
  AUTH_MANAGEMENT_SAVED: 'AUTH_MANAGEMENT_SAVED',
  SAVE_AUTH_MANAGEMENT_FAILED: 'SAVE_AUTH_MANAGEMENT_FAILED',
  RESET_SAVE_AUTH_MANAGEMENT: 'RESET_SAVE_AUTH_MANAGEMENT',
  // ADD AUTH ROLE
  ADD_AUTH_ROLE: 'ADD_AUTH_ROLE',
  ADDING_AUTH_ROLE: 'ADDING_AUTH_ROLE',
  AUTH_ROLE_ADDED: 'AUTH_ROLE_ADDED',
  ADD_AUTH_ROLE_FAILED: 'ADD_AUTH_ROLE_FAILED',
  RESET_ADD_AUTH_ROLE: 'RESET_ADD_AUTH_ROLE',

  // FETCH ROLE PERMISSION
  FETCH_ROLE_PERMISSION: 'FETCH_ROLE_PERMISSION',
  FETCHING_ROLE_PERMISSION: 'FETCHING_ROLE_PERMISSION',
  ROLE_PERMISSION_FETCHED: 'ROLE_PERMISSION_FETCHED',
  FETCH_ROLE_PERMISSION_FAILED: 'FETCH_ROLE_PERMISSION_FAILED',
  OPEN_ROLE_PERMISSION_DIALOG: 'OPEN_ROLE_PERMISSION_DIALOG',
  RESET_ROLE_PERMISSION_DATA: 'RESET_ROLE_PERMISSION_DATA',

  

  // FETCH FILTERED ROLE IN USER PORTAL
  FETCH_ALL_FILTERED_ROLE: 'FETCH_ALL_FILTERED_ROLE',
  FETCHING_ALL_FILTERED_ROLE: 'FETCHING_ALL_FILTERED_ROLE',
  FILTERED_ROLE_FETCHED: 'FILTERED_ROLE_FETCHED',
  FETCH_ALL_FILTERED_ROLE_FAILED: 'FETCH_ALL_FILTERED_ROLE_FAILED',
  // FETCH FILTERED ROLE IN USER PORTAL
  FETCH_STATUS: 'FETCH_STATUS',
  FETCHING_STATUS: 'FETCHING_STATUS',
  STATUS_FETCHED: 'STATUS_FETCHED',
  FETCH_STATUS_FAILED: 'FETCH_STATUS_FAILED',




// Fetch Merchant Users
FETCH_SETTINGS_MERCHANT_USERS: 'FETCH_SETTINGS_MERCHANT_USERS',
FETCHING_SETTINGS_MERCHANT_USERS: 'FETCHING_SETTINGS_MERCHANT_USERS',
LOADING_MORE_SETTINGS_MERCHANT_USERS: 'LOADING_MORE_SETTINGS_MERCHANT_USERS',
SETTINGS_MERCHANT_USERS_FETCHED: 'SETTINGS_MERCHANT_USERS_FETCHED',
FETCH_SETTINGS_MERCHANT_USERS_FAILED: 'FETCH_SETTINGS_MERCHANT_USERS_FAILED',
// SET MERCHANT USER VALUE
SET_SETTINGS_MERCHANT_USER_DATA: 'SET_SETTINGS_MERCHANT_USER_DATA',
// UPDATE USER IN USER PORTAL
UPDATE_MERCHANT_USER: 'UPDATE_MERCHANT_USER',
UPDATING_MERCHANT_USER: 'UPDATING_MERCHANT_USER',
MERCHANT_USER_UPDATED: 'MERCHANT_USER_UPDATED',
UPDATE_MERCHANT_USER_FAILED: 'UPDATE_MERCHANT_USER_FAILED',
// ADD USER IN USER PORTAL
ADD_MERCHANT_USER: 'ADD_MERCHANT_USER',
ADDING_MERCHANT_USER: 'ADDING_MERCHANT_USER',
MERCHANT_USER_ADDED: 'MERCHANT_USER_ADDED',
RESET_ADD_USER_FORM: 'RESET_ADD_USER_FORM',
ADD_MERCHANT_USER_FAILED: 'ADD_MERCHANT_USER_FAILED',
// DEACTIVATE USER IN USER PORTAL
DEACTIVATE_MERCHANT_USER: 'DEACTIVATE_MERCHANT_USER',
DEACTIVATING_MERCHANT_USER: 'DEACTIVATING_MERCHANT_USER',
MERCHANT_USER_DEACTIVATED: 'MERCHANT_USER_DEACTIVATED',
RESET_EDIT_USER_FORM: 'RESET_EDIT_USER_FORM',
UPDATE_MERCHANT_USER_SUCCESS: 'UPDATE_MERCHANT_USER_SUCCESS',
DEACTIVATE_MERCHANT_USER_FAILED: 'DEACTIVATE_MERCHANT_USER_FAILED',
DEACTIVATE_MERCHANT_USER_RESET: 'DEACTIVATE_MERCHANT_USER_RESET',
// RESET USER IN USER PORTAL
RESET_MERCHANT_USER_PASSWORD: 'RESET_MERCHANT_USER_PASSWORD',
RESETTING_MERCHANT_USER_PASSWORD: 'RESETTING_MERCHANT_USER_PASSWORD',
MERCHANT_USER_PASSWORD_RESET_SUCCESS: 'MERCHANT_USER_PASSWORD_RESET_SUCCESS',
MERCHANT_USER_PASSWORD_RESET_FAILED: 'MERCHANT_USER_PASSWORD_RESET_FAILED',
MERCHANT_USER_PASSWORD_RESET_ERROR: 'MERCHANT_USER_PASSWORD_RESET_ERROR',
RESET_MERCHANT_USER_PASSWORD_RESET: 'RESET_MERCHANT_USER_PASSWORD_RESET',
// NEW RESET PASSWORD
NEW_RESET_MERCHANT_USER_PASSWORD: 'NEW_RESET_MERCHANT_USER_PASSWORD',
NEW_RESETTING_MERCHANT_USER_PASSWORD: 'NEW_RESETTING_MERCHANT_USER_PASSWORD',
NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS: 'NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS',
NEW_MERCHANT_USER_PASSWORD_RESET_FAILED: 'NEW_MERCHANT_USER_PASSWORD_RESET_FAILED',
NEW_MERCHANT_USER_PASSWORD_RESET_ERROR: 'NEW_MERCHANT_USER_PASSWORD_RESET_ERROR',
NEW_RESET_MERCHANT_USER_PASSWORD_RESET: 'NEW_RESET_MERCHANT_USER_PASSWORD_RESET',

// FETCH ALL MIN MAX AMOUNT OF TRANSACTION
FETCH_MIN_MAX_AMOUNT: 'FETCH_MIN_MAX_AMOUNT',
FETCHING_MIN_MAX_AMOUNT: 'FETCHING_MIN_MAX_AMOUNT',
FETCHED_MIN_MAX_AMOUNT: 'FETCHED_MIN_MAX_AMOUNT',
FETCH_MIN_MAX_AMOUNT_FAILED: 'FETCH_MIN_MAX_AMOUNT_FAILED',

fetchMerchantUsers: (payload) => ({
  type: actions.FETCH_SETTINGS_MERCHANT_USERS,
  payload: payload,
}),
setMerchantUserData: (payload) => ({
  type: actions.SET_MERCHANT_USER_DATA,
  payload: payload,
}),
updateMerchantUser: (payload) => ({
  type: actions.UPDATE_MERCHANT_USER,
  payload,
}),
addMerchantUser: (payload) => ({
  type: actions.ADD_MERCHANT_USER,
  payload,
}),
deactivateMerchantUser: (payload) => ({
  type: actions.DEACTIVATE_MERCHANT_USER,
  payload,
}),
resetAddUserForm: () => ({
  type: actions.RESET_ADD_USER_FORM,
}),
resetEditUserForm: (payoad) => ({
  type: actions.RESET_EDIT_USER_FORM,
}),
resetPassword: (payload) => ({
  type: actions.RESET_MERCHANT_USER_PASSWORD,
  payload: payload,
}),



  // AUTH MANAGEMENT
  fetchAuthManagementRole: () => ({
    type: actions.FETCH_AUTH_MANAGEMENT_ROLE,
  }),
  saveAuthManagement: (payload) => ({
    type: actions.SAVE_AUTH_MANAGEMENT,
    payload: payload,
  }),
  resetSaveAuthManagement: () => ({
    type: actions.RESET_SAVE_AUTH_MANAGEMENT,
  }),
  addAuthRole: (payload) => ({
    type: actions.ADD_AUTH_ROLE,
    payload: payload,
  }),
  resetAddAuthRole: () => ({
    type: actions.RESET_ADD_AUTH_ROLE,
  }),


  fetchRolePermission: (payload) => ({
    type: actions.FETCH_ROLE_PERMISSION,
    payload,
  }),


  fetchAllFilteredRole: () => ({
    type: actions.FETCH_ALL_FILTERED_ROLE,
  }),

  toggleRolePermissionDialog: () => ({
    type: actions.OPEN_ROLE_PERMISSION_DIALOG,
  }),
  fetchStatus: () => ({
    type: actions.FETCH_STATUS,
  }),
  
  resetRolePermissionData: () => ({
    type: actions.RESET_ROLE_PERMISSION_DATA,
  }),

  //fetch all minimum and maximum amount of transaction
  fetchMinMaxAmount: () => ({
    type: actions.FETCH_MIN_MAX_AMOUNT,
  }),

};
export default actions;
