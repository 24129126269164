import actions from './actions';
import authActions from '../auth/actions';
import * as moment from 'moment-timezone';
// import dummyTransactions from './dummyData'

const initState = {
  sendingEmail: false,
  sendEmailSuccess: false,
  sendEmailFailed: false,
  sendEmailMessage: null,
  idToken: null,
  errorMessage: null,
  message: null,
  isLoading: false,
  searching: false,
  tagSaving: false,
  tagLoading: false,
  menuEventLabel: null,
  menuEventType: null,
  menuEventAction: null,
  showMenuMessage: false,
  menuEventTriggered: false,
  tagDialogVisible: false,
  tagErrorMessage: null,
  tagEditDialogVisible: false,
  tagDeleting: false,
  tagMoving: false,
  folderSaving: false,
  folderDialogVisible: false,
  folderEditDialogVisible: false,
  folderErrorMessage: null,
  folderDeleting: false,
  folderTxnDeleting: false,
  filterPanelVisible: false,
  tabletSearchVisible: false,
  loadingMoreTransactions: false,
  storeListVisible: false,
  searchKeyword: null,
  searchValue: null,
  hasSearchValue: false,
  currentTransaction: null,
  transactions: null,
  transactionLoading: false,
  scrollId: null,
  moduleTitle: null,
  moduleIcon: null,
  // FETCHING ES TRX
  esTrxValue: null,
  fetchingEsTrx: false,
  fetchingEsTrxSuccess: false,
  fetchingEsTrxError: false,
  allowedPchannels: null,
  loadingStoreAssets: false,
  loadingPendingTrx: false,
  loadingPaidTrx: false,
  loadingRefundedTrx: false,
  loadingFailedTrx: false,
  customFolders: null,
  fetchingFolders: false,
  foldersFetched: false,
  tags: null,
  setEditFolderData: null,
  setEditTagData: null,
  remarksPosting: false,
  remarksPostingError: null,
  transactionCount: 0,
  mqttTrxBoolean: false,
  submittingTempTransaction: false,
  tempTransactionError: null,
  tempTransaction: null,
  submittingCustRedTransaction: false,
  custRedirectTransactionError: null,
  custRedirectTransaction: null,
  custRedirectPchannelData: null,
  updateTrxCustomeSuccess: null,
  view: null,
  mqttTrx: null,
  folderMoving: false,
  tagFolderMessage: null,
  txnTagDeleting: false,
  searchingLogs: false,
  searchResult: null,
  searchCount: 0,
  loadingMoreLogs: false,
  loadedMoreLogs: null,
  trxPdfDownloading: false,
  trxPdfDownload: null,
  trxPdfDownloadMessage: null,
  trxPdfDownloadFailed: false,
  bocTrxData: null,
  loadingDetailedTrxData: false,
  detailedTrxData: null,
  merchantLogo: null,
  hasMerchantLogo: false,
  modules: [
    {
      id: 'all',
      label: 'Biz.transactions.menu.all',
      view: 'All'
    },
    {
      id: 'pending',
      label: 'Biz.transactions.menu.pending',
      view: 'Pending'
    },
    {
      id: 'paid',
      label: 'Biz.transactions.menu.paid',
      view: 'Paid'
    },
    {
      id: 'refund',
      label: 'Biz.transactions.menu.refund',
      view: 'Refund'
    },
    {
      id: 'failed',
      label: 'Biz.transactions.menu.failed',
      view: 'Failed'
    },
    {
      id: 'expired',
      label: 'Biz.transactions.menu.expired',
      view: 'Expired'
    }
  ],
  transactionFees: {
    nonbank_otc: {
      percent: 0.025,
      peso: 25.00
    },
    wallet: {
      percent: 0.025,
      peso: 0.00
    },
    onlinebanktransfer: {
      percent: 0.025,
      peso: 0.00
    },
    bank_otc: {
      percent: 0.025,
      peso: 25.00
    },
  },
  inventoryLoading: false,
  inventoryErrorMessage: null,
  storeInventory: null,
  provinceLoading: false,
  provinceShippingLoading: false,
  provinceErrorMessage: null,
  provinces: null,
  provincesShipping: null,
  cityLoading: false,
  cityLoadingShipping: false,
  cityErrorMessage: null,
  cities: null,
  citiesShipping: null,
  brgyLoadingShipping: false,
  brgyErrorMessage: null,
  brgysShipping: null,
  tempTransactionData: null,
  tempTransactionDataLoading: false,
  tempTransactionId: null,
  statusResult: null,
  customerChoosePmethod: null,
  storeData: null,
  refundMessage: null,
  refundCode: null,
  issuingRefund: false,
  deleteTransactionMessage: null,
  loadingApplication: false,
  applicationSuccess: false,
  applicationDone: false,
  bizModule: [],
  errorMessagePreRegister: null,
  threshold: null,
  thresholdLoading: false,
  thresholdError: null,
  inventoryCount: null,
  inventoryCounting: false,
  tempErrorMessage: null,
  redirectSubmittingTrx: false,
  redirectSubmittedTrx: null,
  redirectSubmitMessage: null,
  fetchingRedirectTrxStatus: false,
  redirectTrxStatus: null,
  paymentFees: null,
  merchantPaymentFees: null,
  fetchedFees: null,
  fetchingFees: false,
  sendingSms: false,
  sendingSmsSuccess: false,
  sendingSmsMessage: false,
  cancellingTransaction: false,
  cancelTransactionFailed: false,
  cancelTransactionMessage: null,
  validatingApplication: false,
  validateResponse: null,
  trxRecord: null,
  isFetchingPaymentTransaction: false,
  shippingSetup: null,
  isShippingSetup: false,
  merchantTemplate: null,
  // url: null,
};

export default (state = initState, action) => {

  switch (action.type) {
    case actions.LOAD_TRANSACTION:
      return initState;
    case actions.START_SEARCH:
      return {
        ...state,
        searchKeyword: action.keyword,
        searching: true,
        hasSearchValue: false,
        searchValue: null,
      };
    case actions.END_SEARCH:
      return {
        ...state,
        searchKeyword: action.keyword,
        searching: false,
        hasSearchValue: true,
        searchValue: action.searchValue,
      };
    case actions.TOGGLE_FILTER_PANEL:
      return {
        ...state,
        filterPanelVisible: !state.filterPanelVisible,
      };
    case actions.TOGGLE_TAG_DIALOG:
      return {
        ...state,
        tagDialogVisible: !state.tagDialogVisible,
      };
    case actions.TOGGLE_STORE_LIST_DIALOG:
      return {
        ...state,
        storeListVisible: !state.storeListVisible,
      };
    case actions.LOADING_TRANSACTIONS:
      return {
        ...state,
        transactionLoading: true,
      };
    case actions.LOADING_MORE_TRANSACTIONS:
      return {
        ...state,
        loadingMoreTransactions: true,
      };
    case actions.TRANSACTIONS_LOADED:
      const trx = action.transactions ? {
        ...state.transactions,
        data: action.transactions,
      } : null;
      return {
        ...state,
        loadingMoreTransactions: false,
        transactionLoading: false,
        scrollId: action.transactions ? action.scrollId : null,
        transactions: trx,
        transactionCount: action.transactionCount,
      };
    case actions.SEARCH_TRANSACTIONS_LOADED:
      return {
        ...state,
        loadingMoreTransactions: false,
        transactionLoading: false,
        scrollId: action.transactions ? action.scrollId : null,
        transactions: action.transactions ? action.transactions : null,
        transactionCount: action.transactionCount ? action.transactionCount : null,
      };
    case actions.LOAD_TRANSACTIONS_FAILED:
      return {
        ...state,
        loadingMoreTransactions: false,
        transactionLoading: false,
      };
    case actions.TOGGLE_FOLDER_DIALOG:
      return {
        ...state,
        folderDialogVisible: !state.folderDialogVisible,
      };
    case actions.TOGGLE_EDIT_FOLDER_DIALOG:
      return {
        ...state,
        folderEditDialogVisible: action.payload,
      };
    case actions.TOGGLE_EDIT_TAG_DIALOG:
      return {
        ...state,
        tagEditDialogVisible: action.payload,
      };
    case actions.TOGGLE_TABLET_SEARCH_DIALOG:
      return {
        ...state,
        tabletSearchVisible: !state.tabletSearchVisible,
      };
    case actions.SET_TITLE:
      return {
        ...state,
        moduleTitle: action.payload.title,
        moduleIcon: action.payload.icon,
      };
    case actions.SET_CURRENT_TRANSACTION:
      return {
        ...state,
        currentTransaction: action.payload,
      };
    case actions.SET_EDIT_FOLDER_DATA:
      return {
        ...state,
        setEditFolderData: action.payload,
      };
    case actions.SET_EDIT_TAG_DATA:
      return {
        ...state,
        setEditTagData: action.payload,
      };
    case actions.TAG_SAVING:
      return {
        ...state,
        tagSaving: true,
      };
    case actions.ISSUING_REFUND:
      return {
        ...state,
        issuingRefund: true,
      };
    case actions.REFUND_ISSUED:
      const transIndex = state.transactions.data.findIndex((item, i) => {
        return action.requestId === item.requestId;
      });

      const updatedTransactions = state.transactions.data.filter((el) => {
        if (el.requestid === action.requestId) {
          el.status = action.responseMessage;
          el.responsemess = action.responseMessage;
          el.responsecode = action.refundCode;
          return el;
        }
        else {
          return el;
        }
      });
      return {
        ...state,
        issuingRefund: false,
        transactions: {
          ...state.transactions,
          data: updatedTransactions,
        },
        currentTransaction: updatedTransactions[transIndex],
      };
    case actions.ISSUE_REFUND_FAILED:
      return {
        ...state,
        issuingRefund: false,
      };
    case actions.SHOW_MENU_EVENT_MESSAGE:
      return {
        ...state,
        showMenuMessage: action.payload,
      };
    case actions.TAG_FOLDER_MESSAGE:
      return {
        ...state,
        tagFolderMessage: action.payload,
      };
    case actions.MENU_EVENT_TRIGGERING:
      return {
        ...state,
        menuEventTriggered: action.triggering !== undefined ? action.triggering : action.payload,
      };
    case actions.MENU_EVENT_MESSAGE:
      return {
        ...state,
        menuEventLabel: action.payload.label,
        menuEventType: action.payload.type,
        menuEventAction: action.payload.action,
      };
    // case actions.LOAD_TAGS_FAILED:
    //   return {
    //     ...state,
    //     tagLoading: false,
    //   };
    case actions.REFUND_MESSAGE:
      return {
        ...state,
        refundMessage: action.payload,
        refundCode: action.refundCode,
      };
    case actions.LOAD_TAGS_TRANSACTION:
      return {
        ...state,
        tags: action.tags,
      };
    case actions.TAG_DELETING:
      return {
        ...state,
        tagDeleting: true,
      };
    case actions.TAG_MOVING:
      return {
        ...state,
        tagMoving: true,
      };
    case actions.TAG_MOVED:
      return {
        ...state,
        tagMoving: false,
      };
    case actions.TAG_ADDED:
      return {
        ...state,
        tagDialogVisible: false,
        tagSaving: false,
        tags: [
          ...state.tags,
          {
            id: action.id,
            label: action.label,
            tagColor: action.color
          },
        ],
      };
    case actions.TAG_DELETED:
      // const newTags = state.tags.filter((el) => {
      //   return action.id.indexOf(el.id) < 0; // remove deleted tag form array
      // });
      return {
        ...state,
        tagDeleting: false,
      };
    case actions.TAG_ADD_FAILED:
      return {
        ...state,
        tagSaving: false,
        tagDialogVisible: false,
        tagErrorMessage: 'Error saving tag, please try again later',
      };
    case actions.TAG_DELETE_FAILED:
      return {
        ...state,
        tagDeleting: false,
        tagErrorMessage: 'Error deleting tag, please try again later',
      };
    case actions.FOLDER_SAVING:
      return {
        ...state,
        folderSaving: true,
      };
    case actions.LOAD_FOLDERS_TRANSACTION:
      return {
        ...state,
        customFolders: action.customFolders,
      };
    case actions.LOADING_FOLDERS:
      return {
        ...state,
        fetchingFolders: !state.fetchingFolders,
      };
    case actions.FOLDERS_LOADEDED:
      return {
        ...state,
        foldersFetched: true,
      };
    case actions.FOLDER_DELETING:
      return {
        ...state,
        folderDeleting: true,
      };
    case actions.FOLDER_TRANSACTION_DELETING:
      return {
        ...state,
        folderTxnDeleting: true,
      };
    case actions.FOLDER_ADDED:
      return {
        ...state,
        folderDialogVisible: false,
        folderSaving: false,
        customFolders: [
          ...state.customFolders,
          {
            id: action.id,
            label: action.label,
            icon: 'Folder'
          },
        ],
      };
    case actions.FOLDER_DELETED:
      // const newCustomFolders = state.customFolders.filter((el) => {
      //   return action.id.indexOf(el.id) < 0; // remove deleted folder form array
      // });

      return {
        ...state,
        folderDeleting: false,
      };
    case actions.FOLDER_TRANSACTION_DELETED:
      return {
        ...state,
        folderTxnDeleting: false,
        deleteTransactionMessage: action.payload,
      };
    case actions.FOLDER_ADD_FAILED:
      return {
        ...state,
        folderSaving: false,
        folderDialogVisible: false,
        folderErrorMessage: 'Error saving folder, please try again later',
      };
    case actions.FOLDER_DELETE_FAILED:
      return {
        ...state,
        folderDeleting: false,
        folderErrorMessage: 'Error deleting folder, please try again later',
      };
    case actions.FOLDER_TRANSACTION_DELETE_FAILED:
      return {
        ...state,
        folderTxnDeleting: false,
        folderTxnErrorMessage: 'Error deleting folder transaction, please try again later',
      };
    case actions.CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        errorMessage: null,
        tagErrorMessage: null,
        folderErrorMessage: null,
      };
    // FETCHING ES
    case actions.FETCHING_ES_TRX:
      return {
        ...state,
        fetchingEsTrx: !state.fetchingEsTrx,
      };
    case actions.ES_TRX_VALUE:
      return {
        ...state,
        esTrxValue: action.trxData,
      };
    case actions.FETCHING_ES_TRX_SUCCESS:
      return {
        ...state,
        fetchingEsTrxSuccess: true,
      };
    case actions.FETCHING_ES_TRX_ERROR:
      return {
        ...state,
        fetchingEsTrxError: true,
      };
    case authActions.LOGOUT:
      // reset all redux param values
      return initState;
    case actions.CLEAR_TRANSACTION:
      return {
        ...state,
        transactions: null,
        scrollId: null,
        transactionLoading: false,
        loadingPendingTrx: false,
        loadingRefundedTrx: false,
        loadingFailedTrx: false,
        loadingPaidTrx: false,
      };
    case actions.CLEAR_TRANSACTION_ONLY:
      return {
        ...state,
        transactions: null,
        currentTransaction: null,
      };
    case actions.REMARKS_POSTING:
      return {
        ...state,
        remarksPosting: true,
      };
    case actions.REMARKS_POSTED:
      const formattedDate = moment(action.date).format('YYYY-MM-DDTHH:mm:ssZ');
      const indexOfTransaction = state.transactions.data.findIndex((item, i) => {
        return action.requestId === item.orgtrxid;
      });

      if (indexOfTransaction >= 0) {
        const commentedTransactions = state.transactions.data[indexOfTransaction];
        if (commentedTransactions && commentedTransactions.logs) {
          state.transactions.data[indexOfTransaction].logs.push({
            date_time: formattedDate,
            message: action.remarks,
            type: "INFO",
          });
        } else {
          state.transactions.data[indexOfTransaction].logs = [{
            date_time: formattedDate,
            message: action.remarks,
            type: "INFO",
          }];
        }
      }
      return {
        ...state,
        remarksPosting: false,
        // transactions: newTransactions,
      };
    case actions.REMARKS_POST_FAILED:
      return {
        ...state,
        remarksPosting: false,
        remarksPostingError: '', // payload err here
      };
    case actions.LOADING_STORE_INVENTORY:
      return {
        ...state,
        inventoryLoading: true,
        loadingStoreAssets: true,
      };
    case actions.STORE_INVENTORY_LOADED:
      return {
        ...state,
        inventoryLoading: false,
        storeInventory: action.inventory,
      };
    case actions.RESET_STORE_INVENTORY:
      return {
        ...state,
        inventoryLoading: false,
        storeInventory: null,
      };
    case actions.LOAD_STORE_INVENTORY_FAILED:
      return {
        ...state,
        inventoryLoading: false,
        loadingStoreAssets: false,
        storeInventory: null,
        inventoryErrorMessage: '', // payload err here
      };
    case actions.LOADING_ALLOWED_PCHANNEL:
      return {
        ...state,
        loadingStoreAssets: true,
      };
    case actions.ALLOWED_PCHANNEL_LOADED:
      return {
        ...state,
        inventoryLoading: false,
        loadingStoreAssets: false,
        allowedPchannels: action.pchannels,
      };
    case actions.RESET_ALLOWED_PCHANNEL:
      return {
        ...state,
        inventoryLoading: false,
        loadingStoreAssets: false,
        allowedPchannels: null,
      };
    case actions.LOAD_ALLOWED_PCHANNEL_FAILED:
      return {
        ...state,
        inventoryLoading: false,
        loadingStoreAssets: false,
        allowedPchannels: null,
        inventoryErrorMessage: '', // payload err here
      };
    case actions.RESET_PROVINCE:
      return {
        ...state,
        brgys: null,
        cities: null,
        provinces: null,
      };
    case actions.RESET_PROVINCE_S:
      return {
        ...state,
        brgysShipping: null,
        citiesShipping: null,
        provincesShipping: null,
      };
    case actions.LOADING_PROVINCE:
      return {
        ...state,
        provinceLoading: true,
      };
    case actions.LOADING_PROVINCE_S:
      return {
        ...state,
        provinceShippingLoading: true,
      };
    case actions.PROVINCE_LOADED:
      return {
        ...state,
        provinceLoading: false,
        provinces: action.provinces,
      };
    case actions.PROVINCE_LOADED_S:
      return {
        ...state,
        provinceShippingLoading: false,
        provincesShipping: action.provinces,
      };
    case actions.LOAD_PROVINCE_FAILED:
      return {
        ...state,
        provinceLoading: false,
        provinces: null,
        provinceErrorMessage: '', // payload err here
      };
    case actions.RESET_CITY:
      return {
        ...state,
        brgys: null,
        cities: null,
      };
    case actions.RESET_CITY_S:
      return {
        ...state,
        brgysShipping: null,
        citiesShipping: null,
      };
    case actions.LOADING_CITY:
      return {
        ...state,
        cityLoading: true,
      };
    case actions.LOADING_CITY_S:
      return {
        ...state,
        cityLoadingShipping: true,
      };
    case actions.CITY_LOADED:
      return {
        ...state,
        cityLoading: false,
        cities: action.cities,
      };
    case actions.CITY_LOADED_S:
      return {
        ...state,
        cityLoadingShipping: false,
        citiesShipping: action.cities,
      };
    case actions.LOAD_CITY_FAILED:
      return {
        ...state,
        cityLoading: false,
        cities: null,
        cityErrorMessage: '', // payload err here
      };
    case actions.RESET_BRGY:
      return {
        ...state,
        brgys: null,
      };
    case actions.RESET_BRGY_S:
      return {
        ...state,
        brgysShipping: null,
      };
    case actions.LOADING_BRGY:
      return {
        ...state,
        brgyLoading: true,
      };
    case actions.LOADING_BRGY_S:
      return {
        ...state,
        brgyLoadingShipping: true,
      };
    case actions.BRGY_LOADED:
      return {
        ...state,
        brgyLoading: false,
        brgys: action.brgys,
      };
    case actions.BRGY_LOADED_S:
      return {
        ...state,
        brgyLoadingShipping: false,
        brgysShipping: action.brgys,
      };
    case actions.LOAD_BRGY_FAILED:
      return {
        ...state,
        brgyLoading: false,
        brgys: null,
        brgyErrorMessage: '', // payload err here
      };
    case actions.TEMP_TRANSACTION_LOADED:
      return {
        ...state,
        tempTransactionData: action.data,
        tempTransactionId: action.dataId,
        storeData: action.storeData,
        statusResult: action.statusResult,
        customerChoosePmethod: action.customerChoosePmethod,
        tempTransactionDataLoading: false,
        merchantTemplate: action.template
      };
    case actions.LOAD_TEMP_TRANSACTION_FAILED:
      return {
        ...state,
        merchantTemplate: null
      };
    case actions.LOADING_TEMP_TRANSACTION:
      return {
        ...state,
        tempTransactionDataLoading: true,
      };
    case actions.SUBMITTING_TEMP_TRX:
      return {
        ...state,
        submittingTempTransaction: true,
      };
    case actions.SUBMITTING_REDIRECT_CUSTOMER_TRX:
      return {
        ...state,
        submittingCustRedTransaction: true,
      };
    case actions.TEMP_TRX_SUBMITTED:
      return {
        ...state,
        submittingTempTransaction: false,
        tempTransaction: action.payload,
      };
    case actions.REDIRECT_CUSTOMER_TRX_SUBMITTED:
      return {
        ...state,
        submittingCustRedTransaction: false,
        custRedirectTransaction: action.payload,
      };
    case actions.SUBMIT_TRX_REDIRECT_CUSTOMER_ERROR:
      return {
        ...state,
        submittingCustRedTransaction: false,
        custRedirectTransaction: null,
        custRedirectTransactionError: action.payload ? action.payload : 'Failed to send transaction, please try again later.',
      };
    case actions.RESET_TRX_REDIRECT_CUSTOMER_ERROR:
      return {
        ...state,
        submittingCustRedTransaction: false,
        custRedirectTransaction: null,
        custRedirectTransactionError: null,
      };
    case actions.REDIRECT_CUSTOMER_PCHANNEL_DATA:
      return {
        ...state,
        custRedirectPchannelData: action.payload,
      };
    case actions.UPDATED_TRX_TEMP:
      return {
        ...state,
        updateTrxCustomeSuccess: true,
      };
    case actions.URL:
      return {
        ...state,
        url: action.payload,
      };
    case actions.SUBMIT_TEMP_TRX_FAILED:
      return {
        ...state,
        submittingTempTransaction: false,
        tempTransaction: '',
        tempTransactionError: action.payload ? action.payload : 'Failed to send transaction, please try again later.'
      };
    case actions.SUBMIT_TEMP_TRX_RESET:
      return {
        ...state,
        submittingTempTransaction: false,
        tempTransaction: null,
        tempTransactionError: null
      };
    case actions.MQTT_INCOMMING_LOADING:
      return {
        ...state,
        mqttTrxBoolean: action.mqttTrxBoolean,
      };
    case actions.SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case actions.MQTT_TRX:
      return {
        ...state,
        mqttTrx: action.payload,
      };
    case actions.FOLDER_MOVING:
      return {
        ...state,
        folderMoving: true,
      };
    case actions.FOLDER_MOVED:
      return {
        ...state,
        folderMoving: false,
      };
    //deleting txn tag
    case actions.REMOVING_TXN_TAG:
      return {
        ...state,
        txnTagDeleting: true,
      };
    case actions.TXN_TAG_REMOVED:
      return {
        ...state,
        txnTagDeleting: false,
        deleteTransactionMessage: action.payload,
      };

    case actions.TXN_TAG_REMOVE_FAILED:
      return {
        ...state,
        txnTagDeleting: false,
        tagTxnErrorMessage: 'Error deleting tag of the transaction, please try again later',
      };
    case actions.SUBMITTING_APPLICATION:
      return {
        ...state,
        loadingApplication: true,
      };
    case actions.APPLICATION_SUBMITTED:
      return {
        ...state,
        loadingApplication: false,
        applicationSuccess: action.payload,
        applicationDone: action.payload,
      };
    case actions.RESET_APPLICATION:
      return {
        ...state,
        loadingApplication: false,
        applicationSuccess: false,
        applicationDone: false,
        errorMessagePreRegister: null,
      };
    case actions.SUBMITTING_APPLICATION_FAILED:
      return {
        ...state,
        loadingApplication: false,
        applicationSuccess: false,
        applicationDone: true,
      };
    case actions.SUBMITTING_APPLICATION_ERROR:
      return {
        ...state,
        errorMessagePreRegister: action.payload,
      };
    case actions.SET_BIZ_MODULE:
      return {
        ...state,
        bizModule: action.payload,
      };
    case actions.GETTING_THRESHOLD:
      return {
        ...state,
        thresholdLoading: true,
      };
    case actions.GOT_THRESHOLD:
      return {
        ...state,
        thresholdLoading: false,
        threshold: action.payload,
      };
    case actions.GETTING_THRESHOLD_FAILED:
      return {
        ...state,
        thresholdLoading: false,
        threshold: null,
        thresholdError: 'GETTING_THRESHOLD_FAILED',
      };
    case actions.COUNTING_STORE_INVENTORY:
      return {
        ...state,
        inventoryCounting: true,
      };
    case actions.COUNT_STORE_INVENTORY_RESULT:
      return {
        ...state,
        inventoryCount: action.count,
        inventoryCounting: false,
      }
    case actions.TEMP_ERROR_MESSAGE:
      return {
        ...state,
        tempErrorMessage: action.payload,
      };
    case actions.SUBMITTING_TEMP_TRANSACTION:
      return {
        ...state,
        redirectSubmittingTrx: true,
      };
    case actions.SUBMITTED_TEMP_TRANSACTION:
      return {
        ...state,
        redirectSubmittingTrx: false,
        redirectSubmittedTrx: action.payload,
      }
    case actions.SUBMIT_TEMP_TRANSACTION_FAILED:
      return {
        ...state,
        redirectSubmittingTrx: false,
        redirectSubmittedTrx: null,
        redirectSubmitMessage: action.payload,
      };
    case actions.RESET_TEMP_TRANSACTION_MESSAGE:
      return {
        ...state,
        redirectSubmittingTrx: false,
        redirectSubmittedTrx: null,
        redirectSubmitMessage: null,
      };
    case actions.FETCHING_TRX_STATUS:
      return {
        ...state,
        fetchingRedirectTrxStatus: true,
      };
    case actions.FETCHED_TRX_STATUS:
      return {
        ...state,
        fetchingRedirectTrxStatus: false,
        redirectTrxStatus: action.result,
      };
    case actions.FETCH_TRX_STATUS_FAILED:
      return {
        ...state,
        fetchingRedirectTrxStatus: false,
        redirectTrxStatus: null,
      };
    case actions.SEARCHING_AUDITLOGS:
      return {
        ...state,
        searchingLogs: true,
      };
    case actions.SEARCHED_AUDITLOGS:
      return {
        ...state,
        searchingLogs: false,
        searchResult: action.result,
        searchCount: action.count,
      };
    case actions.SEARCH_AUDITLOGS_FAILED:
      return {
        ...state,
        searchingLogs: false,
        searchResult: null,
        searchCount: 0,
      };
    case actions.LOADING_MORE_LOGS:
      return {
        ...state,
        loadingMoreLogs: true,
      };
    case actions.LOADED_MORE_LOGS:
      return {
        ...state,
        loadingMoreLogs: false,
        loadedMoreLogs: action.result,
      };
    case actions.LOAD_MORE_LOGS_FAILED:
      return {
        ...state,
        loadingMoreLogs: false,
        loadedMoreLogs: null,
      };
    case actions.RESET_AUDITLOGS:
      return {
        ...state,
        searchingLogs: false,
        loadingMoreLogs: false,
        searchResult: null,
        loadedMoreLogs: null,
        searchCount: 0,
      };
    case actions.DOWNLOADING_TRANSACTION_PDF:
      return {
        ...state,
        trxPdfDownloading: true,
      };
    case actions.DOWNLOADED_TRANSACTION_PDF:
      return {
        ...state,
        trxPdfDownload: action.payload,
        trxPdfDownloading: false,
      };
    case actions.DOWNLOAD_TRANSACTION_PDF_FAILED:
      return {
        ...state,
        trxPdfDownload: null,
        trxPdfDownloading: false,
        trxPdfDownloadMessage: action.message,
        trxPdfDownloadFailed: true,
      };
    case actions.RESET_DOWNLOAD_TRANSACTION_PDF:
      return {
        ...state,
        trxPdfDownload: null,
        trxPdfDownloading: false,
        trxPdfDownloadMessage: null,
        trxPdfDownloadFailed: false,
      };
    case actions.FETCH_PAYMENT_FEES_SUCCESS:
      return {
        ...state,
        paymentFees: action.result,
        merchantPaymentFees: action.payload,
      };
    case actions.FETCHED_TRANSACTION_FEE:
      return {
        ...state,
        fetchedFees: action.result,
        fetchingFees: false,
        merchantTemplate: action.merchantTemplate
      };
    case actions.FETCHING_TRANSCTION_FEE:
      return {
        ...state,
        fetchingFees: true,
      };
    case actions.FETCH_TRANSACTION_FEE_FAILED:
      return {
        ...state,
        fetchingFees: false,
        merchantTemplate: null
      };
    case actions.SENDING_SMS_LINK:
      return {
        ...state,
        sendingSms: true,
      };
    case actions.SENT_SMS_LINK:
      return {
        ...state,
        sendingSms: false,
        sendingSmsSuccess: true,
        sendingSmsMessage: action.message,
      };
    case actions.SENDING_SMS_LINK_FAILED:
      return {
        ...state,
        sendingSms: false,
        sendingSmsSuccess: false,
        sendingSmsMessage: action.message,
      };
    case actions.RESET_SEND_SMS_LINK:
      return {
        ...state,
        sendingSms: false,
        sendingSmsSuccess: false,
        sendingSmsMessage: false,
      };
    case actions.FETCHED_BOC_TRANSACTION_DATA:
      return {
        ...state,
        bocTrxData: action.payload,
      };
    case actions.FETCHING_DETAILED_TRANSACTION_DATA:
      return {
        ...state,
        loadingDetailedTrxData: true,
      };
    case actions.FETCHED_DETAILED_TRANSACTION_DATA:
      return {
        ...state,
        detailedTrxData: action.payload,
        merchantLogo: action.merchantLogo,
        loadingDetailedTrxData: false,
        hasMerchantLogo: action.hasMerchantLogo,
      };
    case actions.FETCH_DETAILED_TRANSACTION_DATA_FAILED:
      return {
        ...state,
        loadingDetailedTrxData: false,
      };
    case actions.CANCELLING_TRANSACTION:
      return {
        ...state,
        cancellingTransaction: true,
      };
    case actions.CANCELED_TRANSACTION:
      const trxIndex = state.transactions.data.findIndex((item, i) => {
        return action.transaction.requestid === item.requestId;
      });

      const cancelledTrx = state.transactions.data.filter((el) => {
        if (el.requestid === action.transaction.requestid) {
          el.status = action.transaction.responsemess;
          el.responsemess = action.transaction.responsemess;
          el.responsecode = action.transaction.responsecode;
          el.logs = action.transaction.logs;
          return el;
        }
        else {
          return el;
        }
      });
      return {
        ...state,
        cancellingTransaction: false,
        cancelTransactionMessage: action.message,
        transactions: {
          ...state.transactions,
          data: cancelledTrx,
        },
        currentTransaction: cancelledTrx[trxIndex],
      };
    case actions.CANCEL_TRANSACTION_FAILED:
      return {
        ...state,
        cancellingTransaction: false,
        cancelTransactionFailed: false,
        cancelTransactionMessage: action.message,
      };
    case actions.CANCEL_TRANSACTION_RESET:
      return {
        ...state,
        cancellingTransaction: false,
        cancelTransactionFailed: false,
        cancelTransactionMessage: null,
      };
    case actions.VALIDATING_APPLICATION:
      return {
        ...state,
        validatingApplication: true,
      };
    case actions.VALIDATED_APPLICATION:
      return {
        ...state,
        validatingApplication: false,
        validateResponse: action.result
      };
    case actions.VALIDATE_APPLICATION_FAILED:
      return {
        ...state,
        validatingApplication: false,
      };
    case actions.RESET_VALIDATION:
      return {
        ...state,
        validateResponse: null,
      };
    case actions.FETCHING_TRANSACTION_PAYMENT:
      return {
        ...state,
        isFetchingPaymentTransaction: true,
      };
    case actions.FETCHED_TRANSACTION_PAYMENT:
      return {
        ...state,
        trxRecord: action.payload,
        isFetchingPaymentTransaction: false,
      };
    case actions.FETCH_TRANSACTION_PAYMENT_FAILED:
      return {
        ...state,
        isFetchingPaymentTransaction: false,
      };
    case actions.FETCHING_SHIPPING_SETUP:
      return {
        ...state,
        isShippingSetup: true,
      };
    case actions.FETCHED_SHIPPING_SETUP:
      return {
        ...state,
        isShippingSetup: false,
        shippingSetup: action.payload,
      };
    case actions.FETCH_SHIPPING_SETUP_FAILED:
      return {
        ...state,
        isShippingSetup: false,
      };
    default:
      return state;
      case actions.SENDING_EMAIL:
      return {
        ...state,
        sendingEmail: true,
      };
    case actions.SENT_EMAIL:
      return {
        ...state,
        sendingEmail: false,
        sendEmailSuccess: true,
        sendEmailFailed: false,
        sendEmailMessage: action.message,
      };
    case actions.SEND_EMAIL_FAILED:
      return {
        ...state,
        sendingEmail: false,
        sendEmailSuccess: false,
        sendEmailFailed: true,
        sendEmailMessage: action.message,
      };
    case actions.RESET_SEND_EMAIL:
      return {
        ...state,
        addingDepartment: false,
        sendEmailSuccess: false,
        sendEmailFailed: false,
        sendEmailMessage: null,
      };

  }
}