import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  paymentCount: null,
  isLoadingDashboard: false,
  hasError: false,
  isRaffleEntry: false,
  raffleEntry: null,
  isSuccessEntry: null,
  isStartRaffle: false,
  winnerRaffle: null,
  isGetWinner: false,
  winner: null,
  isErrorUserSettings: false,
  isGetUserSettings: false,
  userSettings: null,
  isUpdateVat: false,
  kycUrl: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.LOADED_DASHBOARD_DATA:
      return {
        ...state,
        paymentCount: action.payload,
      };
    case actions.LOADING_DASHBOARD_DATA:
      return {
        ...state,
        isLoadingDashboard: action.payload,
      };
    case actions.LOAD_DASHBOARD_ERROR:
      return {
        ...state,
        // hasError: action.payload,
        isLoadingDashboard: false,
      };
    case actions.RESET_DASHBOARD_DATA:
      return {
        ...state,
        // hasError: action.payload,
        isLoadingDashboard: false,
        paymentCount: null,
      };
    case actions.RAFFLE_ENTRY:
      return {
        ...state,
        raffleEntry: action.payload,
        isRaffleEntry: false,
        isSuccessEntry: action.success,
      };
    case actions.SENDING_RAFFLE_ENTRY:
      return {
        ...state,
        isRaffleEntry: action.payload,
      };
    case actions.SENDING_FAILED_RAFFLE_ENTRY:
      return {
        ...state,
        isRaffleEntry: false,
      };
    case actions.STARTING_RAFFLE:
      return {
        ...state,
        isStartRaffle: action.payload,
      };
    case actions.DONE_RAFFLE:
      return {
        ...state,
        winnerRaffle: action.payload,
        isStartRaffle: false,
      };
    case actions.GETTING_WINNERS:
      return {
        ...state,
        isGetWinner: action.payload,
      };
    case actions.GOT_WINNERS:
      return {
        ...state,
        winner: action.payload,
      };
    case actions.GOT_USER_SETTINGS:
      return {
        ...state,
        userSettings: action.payload,
        isGetUserSettings: false,
      };
    case actions.GETTING_USER_SETTINGS:
      return {
        ...state,
        isGetUserSettings: action.payload,
      };
    case actions.UPDATING_VAT_AGGREE:
      return {
        ...state,
        isUpdateVat: action.payload,
      };
    case actions.ERROR_USER_SETTINGS:
      return {
        ...state,
        isErrorUserSettings: action.payload,
      };
    case actions.KYC_URL:
      console.log('KYC_URL1', action.payload);
      return {
        ...state,
        kycUrl: action.payload,
      };
    case authActions.LOGOUT:
      // reset all redux param values
      return initState;
    default:
      return state;
  }
}
